
import axcessApi from "@/plugins/axcessApi";



const state = {
    token: localStorage.getItem('access_token') || null,
    profile: null,
    error: '',
    errored:false
}

const getters = {
    
}

const actions = {
    updateProfile(context, user) {
        return axcessApi.patchRequest(`/user/${user.id}`, {
            first_name: user.first_name,
            last_name: user.last_name,
            title: user.title,
            address: user.address,
            phone1: user.phone1,
            lang_pref: user.lang_pref
        } ).then(r=>{
            context.commit('updateProfile', r.data)
            return r;
        })
      },

      updatePassword(context, user) {
          return axcessApi.patchRequest(`/user/${user.id}`, {
              password: user.password
          } ).then(r=>r)
      }
}

const mutations = {
    updateProfile(state, profile) {
        state.profile = profile;
    },

    setError(state, error) {
        state.error = error;
        state.errored = true;
    },

    clearError(state) {
        state.error = '';
        state.errored = false;
    }
   
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axcessApi from "@/plugins/axcessApi";

const state = {
        roles:[],
        role:null,
        permissions:[]
}

const getters = {}

const actions = {
    deleteRole(context, roleId ){
        return axcessApi.deleteRequest( `/roles/`+ roleId).then(()=>{
            context.commit('deleteRole', roleId );
        })
    },
    newRole(context,payload){
        return axcessApi.postRequest('/roles',payload).then((r)=>{
            context.commit('newRole',r.data);
            return r;
        })
    },
    retriveRoles(context) {
        return axcessApi.getRequest('/roles' ).then(r => {
            context.commit('retriveRoles', r.data);
        })
    },
    retrivePermissions(context) {
        return axcessApi.getRequest('/permissions' ).then(r => {
            context.commit('retrivePermissions', r.data);
        })
    },
    setRole(context, role) {
        context.commit('setRole', role)
      },
    assignRolePermission(context,payload){
        return axcessApi.postRequest('/assignrolepermission/'+payload.role+'/'+payload.permission).then((r)=>{
          JSON.parse(localStorage.getItem('user')).roles[0]?.id==context.state.role.id ? context.dispatch('retriveUserInfo',null ,{ root:true }):"";
          context.commit('updateRoles',r.data);
            return r;
        })
    },
    assignRolePermissions(context,payload){
        return axcessApi.postRequest('/assignrolepermissions/'+payload.role,{permissions:payload.permissions}).then((r)=>{
          JSON.parse(localStorage.getItem('user')).roles[0]?.id==context.state.role.id ? context.dispatch('retriveUserInfo',null ,{ root:true }):"";
          context.commit('updateRoles',r.data);
            return r;
        })
    },
    unassignRolePermission(context,payload){
        return axcessApi.postRequest('/unassignrolepermission/'+payload.role+'/'+payload.permission).then((r)=>{
            JSON.parse(localStorage.getItem('user')).roles[0]?.id==context.state.role.id ? context.dispatch('retriveUserInfo',null ,{ root:true }):"";
            context.commit('updateRoles',r.data);
            return r;
        })
    },
    unassignRolePermissions(context,payload){
        return axcessApi.postRequest('/unassignrolepermissions/'+payload.role,{permissions:payload.permissions}).then((r)=>{
            JSON.parse(localStorage.getItem('user')).roles[0]?.id==context.state.role.id ? context.dispatch('retriveUserInfo',null ,{ root:true }):"";
            context.commit('updateRoles',r.data);
            return r;
        })
    },
    
}

const mutations = {
    deleteRole(state, roleId ){
        state.roles = state.roles.filter(item=> item.id != roleId );
    },
    retriveRoles(state, roles) {
        state.roles = roles;
    },
    retrivePermissions(state, permissions) {
        state.permissions = permissions;
    },
    setRole(state, role) {
        state.role= role;
    },
    newRole(state,payload){
        state.roles.push(payload);
    },
    updateRoles(state,payload){
        state.roles = state.roles.filter(i=>i.id !== payload.id );
        state.roles.push(payload);
        state.role=payload;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
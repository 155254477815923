const state = {
    token: localStorage.getItem('access_token') || null,
    consultants: []
}
const getters = {
}
const actions = {
    setConsultants(context, payload) {
        context.commit('setConsultants', payload);
    }
}
const mutations = {
    setConsultants(state, payload) {
      state.consultants = payload;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
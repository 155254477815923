import axcessApi from "@/plugins/axcessApi";

const state = {
    token: localStorage.getItem('access_token'),
    taskrule: {
        id: null,
        activity:'',
        data:{}
    },
    taskrules: [],
    loading: true,
    user: JSON.parse(localStorage.getItem('user') || null),
}

const getters = {
    //Call your filtered data
}

const actions = {
    postTaskRule(context, payload) {
        return axcessApi.postRequest('/taskrule', payload).then((r) => {
            context.commit('addTaskRule', r.data);
            return r;
        })
    },
    updateTaskRule(context, payload) {
        return axcessApi.patchRequest('/taskrule/' + payload.id, payload).then((r) => {
            context.commit('updateTaskRule', r.data);
            return r;
        })
    },
    retriveTaskRules(context) {
        return axcessApi.getRequest('/taskrule').then(r => {
            if(r)
            context.commit('retriveTaskRule', r.data);
        })
    },
    deleteTaskRule(context, payload) {
        return axcessApi.deleteRequest('/taskrule/' + payload ).then(() => context.commit('deleteTaskRule', payload));
    }
}


const mutations = {
    addTaskRule(state,payload){
        state.taskrules.push(payload);
    },
    updateTaskRule(state, payload) {
        state.taskrules = state.taskrules.filter( i => i.id !== payload.id );
        state.taskrules.push(payload);
    },

    updateTask(state, payload) {
        state.taskrules = state.taskrules.filter(i => i.id != payload.id);
        state.taskrules.push(payload);
    },
    retriveTaskRule(state, tasks) {
        state.taskrules = tasks;
        state.loading = false;
    },
    deleteTaskRule(state, taskId ){
        state.taskrules = state.taskrules.filter(i => i.id != taskId);
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
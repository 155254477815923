import moment from 'moment'
import axcessApi from "@/plugins/axcessApi";
import eventObserver from "@/plugins/eventObserver";
import LogsHistory from "@/plugins/logs_history";
const state = {
  token: localStorage.getItem('access_token'),
  requestItem: {},
  requests: [],
   loading: true,
  filters: {
    owner:{ value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
    status: [{text:"active",value:"active"}],
    clause: {value:"",text:""},
    reqid: {value:"",text:""},
    requester: {value:"",text:""},
    eventype: [],
    original: "",
    releaseFlag: {value:"",text:""},
    reqsource:{text:"",value:""},
    receiveddaterange:[],
    duedaterange:[],
    closeddaterange:[]
  },
  searchHistory: [],
  currentEvent: '',
  currentEventType: '',
  user: JSON.parse(localStorage.getItem('user') || null ),
  stats:{
    requestsRecieved:null,
    requestsRecievedDateFilter:"today",
    requestsDueDateFilter:"today",
    requestsDue:null,
    requestsOnHold:null,
    requestsActive:null,
    requestsLate:null
  },
}

const getters = {
 
  getPurgeRequest(state) {
    let result = state.requests.filter(item =>
      (item.RequestStatus === "Complete" ||
        item.RequestStatus === "Cancel" ||
        item.RequestStatus === "Dispose"));
    let response;
    if (result.length) {
      response = result.filter(j => {
        return moment().diff(j.updated_at, "days") > 370
      })
    }
    return response;
  },
  getDefaultEvents(state) {
    let result = [];
    if (state.requestItem.events) {
      result = state.requestItem.events.filter(obj => {
        return obj.isHiddenEvent == 0 && obj.isPlannedEvent == 0;
      });
    }
    return result;
  },

  getHiddenEvents(state) {
    let result = [];
    if (state.getDefaultEvents.events) {
      result = state.getDefaultEvents.events.filter(obj => {
        return obj.isPlannedEvent == 0;
      });
    }
    return result;
  },

  getDefaultPlanedEvents(state) {
    let result = [];
    if (state.requestItem.events) {
      result = state.requestItem.events.filter(obj => {
        return obj.isHiddenEvent == 0 && obj.isPlannedEvent !== 0;
      });
    }
    return result;
  },

  getHiddenPlanedEvents(state) {
    let result = [];
    if (state.getDefaultEvents.events) {
      result = state.getDefaultEvents.events.filter(obj => {
        return obj.isPlannedEvent !== 0;
      });
    }
    return result;
  },

  getCurrentRequest(state) {
    return state.requestItem
  },

  getRequestItem: (state) => (id) => {
    return state.requests.filter(i=>parseInt(i.id) === parseInt(id))[0]
  }
}
const actions = {
  retriveRequests(context) {
    return axcessApi.getRequest('/requests').then(r => {
      if (r.data){ 
        context.commit('retriveRequests', r.data)
      }
      return r;
    })
  },
  retrieveSearchHistory(context) {
    return axcessApi.getRequest('/historysearch').then(r => {
      if (r.data){ 
        context.commit('retrieveSearchHistory', r.data)
      }
      return r;
    })
  },
  retriveRequestItem(context, id) {
    return axcessApi.getRequest('/requests/' + id).then(r => {
      r.data ? context.commit('retriveRequestItem', r.data) : ""
      return r;
    })
  },
  updateRequest(context, request) { 
    return axcessApi.patchRequest(`/requests/${request['id']}`, Object.assign({}, request) ).then(r => {
      eventObserver.eventObserver('Request', context.state.requestItem, r.data);
      context.commit('updateRequest', r.data)

      return r;
    })
  },
  updateRequestStatus(context, request) {
    return axcessApi.patchRequest(`/requests/${request.id}`, { RequestStatus: request.RequestStatus }).then(r => {
      context.commit('updateRequest', r.data)
      return r;
    })
  },
  addRequest(context, request) {
    return axcessApi.postRequest("/requests", request).then(r => {
      eventObserver.eventObserver('Request', context.state.requestItem, r.data);
      context.commit('addRequest', r.data)
      return r;
    })
  },
  addSearchHistory(context, searchhistory) {
    return axcessApi.postRequest("/historysearch", searchhistory).then(r => {
      context.commit('addSearchHistory', r.data)
      return r;
    })
  },

  deleteSearchHistory(context,payload){
    return axcessApi.deleteRequest('/historysearch/' + payload ).then(()=>{
        context.commit( 'deleteSearchHistory', payload );
    })
  },
  setRequestItem(context, request) {
    context.commit('setRequestItem', request)
  },
  deleteRequestItem(context) {
    context.commit('deleteRequestItem')
  },
  setRequestStatus(context, status) {
    context.commit('setRequestStatus', status)
  },
  setStatsRecievedFilter(context, received_select) {
    context.commit('setStatsRecievedFilter', received_select)
  },
  setStatsDueFilter(context, due_select) {
    context.commit('setStatsDueFilter', due_select)
  },
  setRequestItemStatus(context, status) {
    context.commit('setRequestItemStatus', status)
  },
  searchRequest(context, filters) {
    context.commit('updateSearchResult', filters)
  },
  setRequestItemDate(context, date) {
    context.commit('setRequestItemDate', date)
  },
  setRequestItemDueDate(context, date) {
    context.commit('setRequestItemDueDate', date)
  },
  setRequestItemCompleteReceive(context, date) {
    context.commit('setRequestItemCompleteReceive', date)
  },
  setHoldDays(context, holdDays) {
    context.commit('setHoldDays', holdDays)
  },
  setCurrentEvent(context, evtId) {
    context.commit('setCurrentEvent', evtId);
  },
  setCurrentEventType(context, evtType) {
    context.commit('setCurrentEventType', evtType);
  },
  deleteReqAttachment(context, payload) {
    context.commit('deleteReqAttachment', payload);
  },
  addReqCourt(context, payload) {
    context.commit('addReqCourt', payload);
  },
  clearRequestFilters(context) {
      context.commit('clearRequestFilters');
  },
}


const mutations = {
  deleteReqAttachment(state,payload){
    state.requests.filter(i=>i.id === payload.RequestId).map(j=>{
      j.attachment = j.attachment.filter(k=>k.id !== payload.attachmentId )
    })
  },
  setStatsRecievedFilter(state, payload){
      state.stats.requestsRecievedDateFilter=payload;
  },
  setStatsDueFilter(state, payload){
      state.stats.requestsDueDateFilter=payload;
  },
  setCurrentEvent(state, eventId) {
    state.currentEvent = eventId;
  },
  setCurrentEventType(state, evtType) {
    state.currentEventType = evtType;
  },
  setRequestItemCompleteReceive(state, date) {
    state.requestItem.DateInfoComplete = date;
  },
  setRequestItemDueDate(state, date) {
    state.requestItem.DateDue = date;
  },
  setRequestItemDate(state, date) {
    state.requestItem.DateOfClosure = date;
  },
  setRequestItemStatus(state, status) {
    state.requestItem.RequestStatus = status
  },

  updateSearchResult(state, filters) {
    //state.filters = filters;
    state.filters={};
    Object.assign(state.filters, filters);
  },

  addRequest(state, request) {
    let $logs_history= new LogsHistory(state.requests,request.id,request,'requests');
    $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
    state.requestItem = request;
    state.requests.push(request);
  },

  addSearchHistory(state, searchhistory) {
    state.searchHistory.push(searchhistory);
  },

  deleteSearchHistory(state,payload){
    state.searchHistory = state.searchHistory.filter(i=> i.id !== payload );
  },

  updateRequest(state, request) {
    let $logs_history= new LogsHistory(state.requests,request.id,request,'requests');
    $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
    state.requestItem = request;
    state.requests = state.requests.filter(item => item.id != request.id);
    state.requests.push(request);
  },

  retriveRequests(state,requests) {
    
    state.requests = requests.data;
    state.loading = false;
  },

  retrieveSearchHistory(state,searchhistory) {
    state.searchHistory = searchhistory;
    state.loading = false;
  },

  retriveRequestItem(state, request) {
    state.requestItem = request;
  },

  setRequestItem(state, request) {
    state.requestItem = request;
  },

  deleteRequestItem(state) {
      state.requestItem = {};
  },

  setRequestStatus(state, status) {
    state.status = status;
  },
  setHoldDays(state, holdDays) {
    state.requestItem.HoldDays = holdDays;
  },
  clearRequestFilters(state) {
    let dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
    state.filters ={
      owner:dashboardFilter,
      status: [{text:"active",value:"active"}],
      clause: {value:"",text:""},
      reqid: {value:"",text:""},
      requester: {value:"",text:""},
      eventype: [],
      original: "",
      releaseFlag: {value:"",text:""},
      reqsource:{text:"",value:""},
      receiveddaterange:[],
      duedaterange:[],
      closeddaterange:[]
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
import axcessApi from "@/plugins/axcessApi";
import LogsHistory from "@/plugins/logs_history";



const state = {
    token: localStorage.getItem('access_token') || null,
    addresses: [],
    loading: true,
    filter: {
        AddressType:[],
        Source:[],
        Country:[],
        Province:[],
        PostalCode: "",
    },   
}

const getters = {
    addressesFiltered(state) {
        var result = state.addresses;

        if (state.filter.AddressType.length > 0)
            result = result.filter(item => state.filter.AddressType.map(a => a.text).includes(item.AddressType));

        if (state.filter.Source.length > 0)
            result = result.filter(item => state.filter.Source.map(a => a.text).includes(item.Source));

        if (state.filter.Country.length > 0)
            result = result.filter(item => state.filter.Country.map(a => a).includes(item.Country));
            
        if (state.filter.Province.length > 0)
            result = result.filter(item => state.filter.Province.map(a => a).includes(item.Province));
            
        if (state.filter.PostalCode)
            result = result.filter(item => item.PostalCode == state.filter.PostalCode);

        return result;
    },
}

const actions = {
    retrieveAddresses(context) {
        return axcessApi.getRequest('/address' ).then(r => {
            context.commit('retrieveAddresses', r.data.data);
            return r;
        })
    },
    createAddress(context, payload) {
        return axcessApi.postRequest("/address", payload).then(r=>{
            context.commit('addAddress',r.data);
            return r;
        })
    },

    clearAddressesFilter(context) {
    context.commit('clearAddressesFilter');
    },
    filterAddresses(context, filters) {
    context.commit('filterAddresses', filters)
    },
    updateAddress(context, address) {
        return axcessApi.patchRequest("/address/"+address.id, address).then(r=>{
            context.commit('updateAddress',r.data);
            return r;
        });
    },
    sendConsultation(context, message) {
        return axcessApi.postRequest("/requests", {
            subject: 'Consultation',
            to: message.Email,
            // url: '',
            address_id: message.id,
            // 'greeting' => 'nullable|string',
        }).then(r => r)
    },
    generateUuid(context, message) {
        return axcessApi.postRequest('/emailservice/uuid', {
            address_id: message.agency_id
        }).then(r => r)
    },
    addRequestConsultation(context, consult) {
        return axcessApi.postRequest('/reqconsult', consult).then(r=>r)
    },
    addRequestPcoConsultation(context, consult) {
        return axcessApi.postRequest('/reqpcoconsult', consult).then(r=>r)
    }
}

const mutations = {

    addAddress(state,payload){
        let $logs_history= new LogsHistory(state.addresses,payload.id,payload,'address');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.addresses.push(payload);
    },
    updateAddress(state,payload){
        let $logs_history= new LogsHistory(state.addresses,payload.id,payload,'address');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.addresses = state.addresses.filter(f => f.id !== payload.id);
        state.addresses.push(payload);
    },
    filterAddresses(state, filters) {
        Object.assign(state.filter, filters);
    },
    clearAddressesFilter(state) {
        state.filter = {
            AddressType:[],
            Source:[],
            Country:[],
            Province:[],
            PostalCode: "",
        }
    },
    retrieveAddresses(state, address) {
      state.addresses = address;
      state.loading = false;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axcessApi from "@/plugins/axcessApi";



const state = {
    token: localStorage.getItem('access_token') || null , 
    events_type: [],
}

const getters = {
  getEventsType(state) {
    return state.events;
  }
}

const actions = {
    retrieveEventsType(context) {
        return axcessApi.getRequest('/eventstype' ).then(r => {
            if(r.data) context.commit('retrieveEventsType', r.data.data)
            return r;
        })
    },
}
  

const mutations = {
    retrieveEventsType(state, events_type) {
    state.events_type = events_type;
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


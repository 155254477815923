import axcessApi from "@/plugins/axcessApi";
//import LogsHistory from "@/plugins/logs_history";

const state = {
    token: localStorage.getItem('access_token'),
    requestConfiguration: {
        LookUpTableId:'',
        LookUpName:'',
        LookUpKey:'',
        LookUpStringE:'',
        LookUpStringF:'',
        LookUpOther:'',
        LookUpMoney:''
    },
    configurations: [],
    loading: true
}

const getters = {
    getAllConfiguration(state){
        return state.configurations;
    }
}

const actions = {
    retrievConfiguration(context, id) {
        return axcessApi.getRequest('/lookup/' + id ).then(r => {
            if(r.data) context.commit('retrievConfiguration', r.data)
            return r
        } )
    },
    retriveConfigurations(context) {
        if (context.state.configurations && context.state.configurations.length) {
            return context.state.configurations
        }
        return axcessApi.getRequest('/lookup' ).then(r => {
            if(r.data) context.commit('retriveConfigurations', r.data)
            return r;
        })
    },
    retriveConfigChoices(context) {
        return axcessApi.getRequest('/configchoice' ).then(r => {
            if(r.data) context.commit('retriveConfigChoices', r.data)
            return r;
        } )
    },
    retriveRequestItem(context, id) {
        return axcessApi.getRequest('/lookup/' + id ).then(r => {
            if(r.data) context.commit('retriveRequestItem', r.data)
            return r;
        } )
    },
    addConfiguration(context, request) {
        return axcessApi.postRequest("/lookup", request).then(r=>{
            context.commit('addConfiguration', r.data)
            return r;
        })
    },
    updateConfigChoices(context, request) {
        return axcessApi.postRequest("/configchoice", request).then(r=>{
            context.commit('retriveConfigChoices', r.data)
            return r;
        })
    },
    updateConfiguration(context, request) {
        return axcessApi.patchRequest("/lookup/"+request.LookUpTableId, request ).then(r=>{
            context.commit('updateConfiguration', r.data)
            return r;
        })
    },
    setConfigurationItem(context, configuration) {
        context.commit('retrievConfiguration', configuration)
    },
    removeConfiguration(context, request ){
        return axcessApi.deleteRequest("lookup/" + request ).then(r=>{
            context.commit('removeConfiguration', request)
            return r;
        })
    },
    emptyConfiguration( context ){
        context.commit( 'emptyConfiguration' );
    }
}

const mutations = {
    emptyConfiguration(state){
        state.requestConfiguration = {
            LookUpTableId:'',
            LookUpName:'',
            LookUpKey:'',
            LookUpStringE:'',
            LookUpStringF:'',
            LookUpOther:'',
            LookUpMoney:'',
            LookUpFileName1:'',
            LookUpFileName2:'',
        }
    },
    retrievConfiguration(state, configuration) {
        state.requestConfiguration={};
        Object.assign(state.requestConfiguration, configuration);
    },
    retriveConfigurations(state, configurations) {
        state.configurations = configurations;
        state.loading = false;
    },
    addConfiguration( state, configuration ){
        /* let $logs_history= new LogsHistory(state.configurations,configuration.id,configuration,configuration.LookUpName);
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew())); */
        state.configurations.push(configuration);
        state.requestConfiguration = {
            LookUpName:'',
            LookUpKey:'',
            LookUpStringE:'',
            LookUpStringF:'',
            LookUpOther:'',
            LookUpMoney:''
        }
    },
    updateConfiguration(state, configuration){
       /*  let $logs_history= new LogsHistory(state.configurations,configuration.id,configuration,configuration.LookUpName);
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew())); */
        state.configurations=state.configurations.filter(item=> item.LookUpTableId != configuration.LookUpTableId );
        state.configurations.push(configuration);
    },
    removeConfiguration(state, configuration ){ 
        state.configurations = state.configurations.filter(item=> item.LookUpTableId != configuration );
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
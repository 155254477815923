import axcessApi from "@/plugins/axcessApi";
import LogsHistory from "@/plugins/logs_history";
import moment from "moment";

const state = {
    token: localStorage.getItem('access_token'),
    courts: [],
    status: 'active',
    loading: true,
    activeCourt:[],
    requestId:"" ,
    filter: {
        contactName: "",
        contactFileNumber: "",
        courtFileNumber: "",
        courtComment:"",
        dateAdvised:[],
        dateApplicationFiled:[],
        dateOfDecision:[],
        requestId:[],
        ATIPOfficerId:[],
    },   
}

const getters = {
    courtsFiltered(state) {
        var result = state.courts;
        if (state.filter.contactFileNumber)
            result = result.filter(item => item.contactFileNumber == state.filter.contactFileNumber);

        if (state.filter.contactName)
            result = result.filter(item => item.contactName == state.filter.contactName);

        if (state.filter.courtFileNumber)
            result = result.filter(item => item.courtFileNumber == state.filter.courtFileNumber);

        if (state.filter.courtComment)
            result = result.filter(item => item.courtComment == state.filter.courtComment);

        if (state.filter.requestId.length > 0)
            result = result.filter(item => state.filter.requestId.map(a => a.value).includes(item.request_id));
        
        if (state.filter.ATIPOfficerId.length > 0)
            result = result.filter(item => state.filter.ATIPOfficerId.map(a => a.value).includes(item.ATIPOfficerId));
        
        if (state.filter.dateAdvised.length == 1)
        result = result.filter(item => new Date(moment(item.dateAdvised).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dateAdvised[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateAdvised.length == 2)
            result = result.filter(item =>
                new Date(moment(item.dateAdvised).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dateAdvised[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dateAdvised[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.dateAdvised).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateApplicationFiled.length == 1)
            result = result.filter(item => new Date(moment(item.dateApplicationFiled).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dateApplicationFiled[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateApplicationFiled.length == 2)
            result = result.filter(item =>
                new Date(moment(item.dateApplicationFiled).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dateApplicationFiled[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dateApplicationFiled[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.dateApplicationFiled).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateOfDecision.length == 1)
            result = result.filter(item => new Date(moment(item.dateOfDecision).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dateOfDecision[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateOfDecision.length == 2)
            result = result.filter(item =>
                new Date(moment(item.dateOfDecision).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dateOfDecision[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dateOfDecision[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.dateOfDecision).format("YYYY-MM-DD")).getTime());

        return result;
    },
}

const actions = {

 
    async retriveCourt(context,payload){
        return axcessApi.getRequest("/reqcourt/" + payload ).then(r => {
            if(r.data) context.commit('retriveCourt', r.data)
            return r;
        })
    },
    retriveCourts(context) {
        return axcessApi.getRequest('/reqcourt' ).then(r => {
            if(r.data) context.commit('retriveCourts', r.data)
            return r;
        })
    },
    createCourt(context, payload) {
             return axcessApi.postRequest("/reqcourt", payload).then(r=>{
                context.commit('addCourt',r.data);
            return r;
     })
    },

    clearCourtsFilter(context) {
        context.commit('clearCourtsFilter');
    },
    filterCourts(context, filters) {
        context.commit('filterCourts', filters)
    },
    updateCourt(context, court) {
        return axcessApi.patchRequest("/reqcourt/"+court.id, court).then(r=>{
            context.commit('updateCourt', r.data);
       return r;
    })

},
    
}

const mutations = {
    updateCourt(state,payload){
        let $logs_history= new LogsHistory(state.courts,payload.id,payload,'court');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.courts = state.courts.filter( f => f.id !== payload.id);
        state.courts.push(payload);
    },
    addCourt(state,payload){
        let $logs_history= new LogsHistory(state.courts,payload.id,payload,'court');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.courts.push(payload);
    },
    filterCourts(state, filters) {
        Object.assign(state.filter, filters);
    },
    clearCourtsFilter(state) {
        state.filter = {
            contactName: "",
            contactFileNumber: "",
            courtFileNumber: "",
            courtComment:"",
            dateAdvised:[],
            dateApplicationFiled:[],
            dateOfDecision:[],
            requestId:[],
            ATIPOfficerId:[],
        }
    },
    retriveCourts(state, requests) {
      state.courts = requests.data;
      state.loading = false;
    },
    retriveCourt(state, requests) {
      state.activeCourt = requests.data;
      state.loading = false;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axcessApi from "@/plugins/axcessApi";
import moment from "moment";


const state = {
    token: localStorage.getItem('access_token'),
    correspondences:[], 
    loading: true,
    filter: {
        request_id: [],
        template_id: [],
        date_created: [],
    },   
}



const getters = {
    correspondencesFiltered(state) {
        var result = state.correspondences;
        if (state.filter.request_id.length > 0)
            result = result.filter(item => state.filter.request_id.map(a => a.value).includes(item.request_id));

        if (state.filter.template_id.length > 0)
            result = result.filter(item => state.filter.template_id.map(a => a.value).includes(item.template_id));

        if (state.filter.date_created.length == 1)
        result = result.filter(item => new Date(moment(item.date_created).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.date_created[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.date_created.length == 2)
            result = result.filter(item =>
                new Date(moment(item.date_created).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.date_created[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.date_created[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.date_created).format("YYYY-MM-DD")).getTime());
        return result;
    },
}



const actions = {
    retrieveCorrespondences(context) {
        return axcessApi.getRequest('/correspondence' ).then(r => {
            if(r.data) context.commit('retrieveCorrespondences', r.data)
            return r;
        })
    },
    createCorrespondence(context, payload) {
             return axcessApi.postRequest("/correspondence", payload).then(r=>{
                context.commit('addCorrespondence',r.data);
            return r;
     })
    },
    clearCorrespondencesFilter(context) {
        context.commit('clearCorrespondencesFilter');
    },
    filterCorrespondences(context, filters) {
        context.commit('filterCorrespondences', filters)
    },
    updateCorrespondence(context, correspondence) {
        return axcessApi.patchRequest("/correspondence/"+correspondence.id, correspondence);
    },
    deleteCorrespondence(context, correspondence) {
        return axcessApi.deleteRequest("/correspondence/"+correspondence.id, correspondence);
    }

}
const mutations = {
    addCorrespondence(state,payload){
        state.correspondences.push(payload);
    },
    retrieveCorrespondences(state, requests) {
      state.correspondences = requests.data;
      state.loading = false;
    },
    filterCorrespondences(state, filters) {
        Object.assign(state.filter, filters);
    },
    clearCorrespondencesFilter(state) {
        state.filter = {
            request_id: [],
            template_id: [],
            date_created: [],
        }
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axcessApi from "@/plugins/axcessApi";
import LogsHistory from "@/plugins/logs_history";
import moment from "moment";


const state = {
    token: localStorage.getItem('access_token') || null,
    budget: [],
    expense: [],
    loadingbudget: true,
    loadingexpense: true,
    filter: {
        range:[],
    },   
}

const getters = {
    filteredBudget(state) {
        var result = state.budget;

        if (state.filter.range.length == 1)
            result = result.filter(item => new Date(moment(item.effective_date).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.range[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.range.length == 2)
            result = result.filter(item =>
            new Date(moment(item.effective_date).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.range[0]).format("YYYY-MM-DD")).getTime() &&
            new Date(moment(state.filter.range[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.effective_date).format("YYYY-MM-DD")).getTime());

        return result;
    },
    filteredExpense(state) {
        var result = state.expense;

        if (state.filter.range.length == 1)
            result = result.filter(item => new Date(moment(item.effective_date).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.range[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.range.length == 2)
            result = result.filter(item =>
            new Date(moment(item.effective_date).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.range[0]).format("YYYY-MM-DD")).getTime() &&
            new Date(moment(state.filter.range[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.effective_date).format("YYYY-MM-DD")).getTime());

        return result;
    },
}

const actions = {
    getBudget(context) {
        state.loadingbudget = true;
        return axcessApi.getRequest('/finance' ).then(r => {
            context.commit('getBudget', r.data.data);
            return r;
        })
    },
    addBudget(context,payload){
        state.loadingbudget = true;
        return axcessApi.postRequest('/finance', payload ).then(r=>{
            context.commit( 'addBudget', r.data );
        })
    },
    updateBudget(context,payload){
        state.loadingbudget = true;
        return axcessApi.patchRequest('/finance/' + payload.id , payload).then(r=>{
            context.commit( 'updateBudget', r.data );
        })
    },
    deleteBudget(context,payload){
        state.loadingbudget = true;
        return axcessApi.deleteRequest('/finance/' + payload.id ).then(()=>{
            context.commit( 'deleteBudget', payload );
        })
    },
    filterBudget(context, filters) {
        state.loadingbudget = true;
        context.commit('filterBudget', filters)
    },
    getExpense(context) {
        state.loadingexpense = true;
        return axcessApi.getRequest('/finance' ).then(r => {
            context.commit('getExpense', r.data.data);
            return r;
        })
    },
    addExpense(context,payload){
        state.loadingexpense = true;
        return axcessApi.postRequest('/finance', payload ).then(r=>{
            context.commit( 'addExpense', r.data );
        })
    },
    updateExpense(context,payload){
        state.loadingexpense = true;
        return axcessApi.patchRequest('/finance/' + payload.id , payload).then(r=>{
            context.commit( 'updateExpense', r.data );
        })
    },
    deleteExpense(context,payload){
        state.loadingexpense = true;
        return axcessApi.deleteRequest('/finance/' + payload.id ).then(()=>{
            context.commit( 'deleteExpense', payload );
        })
    },
    filterExpense(context, filters) {
        state.loadingexpense = true;
        context.commit('filterExpense', filters)
    },
}

const mutations = {

    getBudget(state, payload){
        state.budget = payload.filter(item=>item.budgetOrExpense=='Budget');
        state.loadingbudget = false;
    },
    addBudget(state,payload){
        let $logs_history= new LogsHistory(state.budget,payload.id,payload,'budget');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.budget.push(payload);
        state.loadingbudget = false;
    },
    updateBudget(state,payload){
        let $logs_history= new LogsHistory(state.budget,payload.id,payload,'budget');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.budget = state.budget.filter(i=> i.id !== payload.id );
        state.budget.push(payload);
        state.loadingbudget = false;
    },
    deleteBudget(state,payload){
        state.budget = state.budget.filter(i=> i.id !== payload.id );
        state.loadingbudget = false;
    },
    filterBudget(state, filters) {
        Object.assign(state.filter, filters);
        state.loadingbudget = false;
    },
    getExpense(state, payload){
        state.expense = payload.filter(item=>item.budgetOrExpense=='Expense');
        state.loadingexpense = false;
    },
    addExpense(state,payload){
        let $logs_history= new LogsHistory(state.expense,payload.id,payload,'expense');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.expense.push(payload);
        state.loadingexpense = false;
    },
    updateExpense(state,payload){
        let $logs_history= new LogsHistory(state.expense,payload.id,payload,'expense');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.expense = state.expense.filter(i=> i.id !== payload.id );
        state.expense.push(payload);
        state.loadingexpense = false;
    },
    deleteExpense(state,payload){
        state.expense = state.expense.filter(i=> i.id !== payload.id );
        state.loadingexpense = false;
    },
    filterExpense(state, filters) {
        Object.assign(state.filter, filters);
        state.loadingexpense = false;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import store from '../store'
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
export default {
    async eventObserver(action,oldPayload,payload){
        await store.dispatch('eventActions/getEventActions').then(()=>{
            let events = store.state.eventActions.eventactions;
            let payloadA = store.getters["request/getCurrentRequest"];


            //Request Observer Start
            for (let i = 0; i < events.length; i++) {
                if ( events[i].module === action && action === 'Request' ){//console.log(action);
                    for (const [key, value] of Object.entries(payload)) {
                        if( events[i].FieldName === key ){ //console.log( oldPayload, oldPayload[key], value)
                            if( ( oldPayload && (oldPayload[key] !== value) )){
                                if( payload[key] ){

                                    let requestorEmail = payload.address.Email;
                                    let ATIPOfficerEmail = store.state.users.filter(i=>i.id === payload.ATIPOfficerId ).map(j=>j.email)[0];
                                    let ATIPManagerEmail = store.state.users.filter(i=>i.id === payload.Approver ).map(j=>j.email)[0];

                                    if( ! events[i].lookupkey ){ //console.log('1');

                                        store.dispatch('request/retriveTemplate').then(r=>{
                                            for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                                                //console.log('2');
                                                if(JSON.parse( events[i].ActionTodo )[j] === 'sentEmail'){
                                                    //console.log('3');
                                                    let template = r.data.filter(x=>x.id===events[i].emailTemplate)[0];
                                                    let content = this.updateContent(template.content,payload);
                                                    let emailContent = {
                                                        body:content,
                                                        subject:template.name + " Request : " + payload.reqCode
                                                    };
                                                    if(events[i].toSend === 'requestor'){
                                                        emailContent.to = requestorEmail;
                                                        this.sentEmail(emailContent);
                                                    }
                                                    if(events[i].toSend === 'atipmanager'){
                                                        emailContent.to = ATIPOfficerEmail;
                                                        this.sentEmail(emailContent);
                                                    }
                                                    if(events[i].toSend === 'atipofficer'){
                                                        emailContent.to = ATIPManagerEmail;
                                                        this.sentEmail(emailContent);
                                                    }

                                                }
                                                //createTask
                                                if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                                                    //console.log('create task2');
                                                    let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                                                    let task = {
                                                        request_id: payload.reqCode,
                                                        title: eventTitle,
                                                        description: '',
                                                        created_by: store.state.user.id,
                                                        status: 'todo'
                                                    }
                                                    if (payload.ATIPOfficerId && events[i].toSend === 'atipofficer') {
                                                        task.assigned_to = payload.ATIPOfficerId;
                                                        store.dispatch('task/postTask',task);
                                                    }
                                                    if (payload.ATIPOfficerId && events[i].toSend === 'atipmanager') {
                                                        task.assigned_to = payload.ATIPOfficerId;
                                                        store.dispatch('task/postTask',task);
                                                    }
                                                }
                                                //createEvent
                                                if(JSON.parse( events[i].ActionTodo )[j] === 'createEvent'){
                                                    let editedItem = {
                                                        request_id: payload.id,
                                                        startDate: moment().format("YYYY-MM-DD"),
                                                        isPlannedEvent: 0,
                                                        isHiddenEvent: 0,
                                                        standardOfService: "1",
                                                        RequestStatus: payload.RequestStatus,
                                                        responsiblePerson:store.state.user.first_name,
                                                        event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                                                    }
                                                    axcessApi.postRequest("/event", editedItem );
                                                }
                                            }
                                        });
                                    }else{
                                        if(events[i].lookupkey === payload[key]){
                                            store.dispatch('request/retriveTemplate').then(r=>{
                                                for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                                                    if(JSON.parse( events[i].ActionTodo )[j] === 'sentEmail'){
                                                        let template = r.data.filter(x=>x.id===events[i].emailTemplate)[0];
                                                        let content = this.updateContent(template.content,payload);
                                                        let emailContent = {
                                                            body:content,
                                                            subject:template.name + " Request : " + payload.reqCode
                                                        };
                                                        if(events[i].toSend === 'requestor'){
                                                            emailContent.to = requestorEmail;
                                                            this.sentEmail(emailContent);
                                                        }
                                                        if(events[i].toSend === 'atipmanager'){
                                                            emailContent.to = ATIPOfficerEmail;
                                                            this.sentEmail(emailContent);
                                                        }
                                                        if(events[i].toSend === 'atipofficer'){
                                                            emailContent.to = ATIPManagerEmail;
                                                            this.sentEmail(emailContent);
                                                        }

                                                    }
                                                    //createTask
                                                    if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                                                        //console.log('create task2');
                                                        let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                                                        let task = {
                                                            request_id: payload.reqCode,
                                                            title: eventTitle,
                                                            description: '',
                                                            created_by: store.state.user.id,
                                                            status: 'todo'
                                                        }
                                                        if (events[i].toSend === 'atipofficer') {
                                                            task.assigned_to = payload.ATIPOfficerId;
                                                            store.dispatch('task/postTask',task);
                                                        }
                                                        if (events[i].toSend === 'atipmanager') {
                                                            task.assigned_to = payload.ATIPOfficerId;
                                                            store.dispatch('task/postTask',task);
                                                        }

                                                    }
                                                    //createEvent
                                                    if(JSON.parse( events[i].ActionTodo )[j] === 'createEvent'){
                                                        let editedItem = {
                                                            request_id: payload.id,
                                                            startDate: moment().format("YYYY-MM-DD"),
                                                            isPlannedEvent: 0,
                                                            isHiddenEvent: 0,
                                                            standardOfService: "1",
                                                            RequestStatus: payload.RequestStatus,
                                                            responsiblePerson:store.state.user.first_name,
                                                            event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                                                        }
                                                        axcessApi.postRequest("/event", editedItem );
                                                    }
                                                }


                                            });
                                        }
                                    }
                                }

                            }
                        }

                    }
                }
            }
            //End Request Observer

            // Attachment Observer
            let requestorEmail = payloadA.address.Email;
            let ATIPOfficerEmail = store.state.users.filter(i=>i.id === payloadA.ATIPOfficerId ).map(j=>j.email)[0];
            let ATIPManagerEmail = store.state.users.filter(i=>i.id === payloadA.Approver ).map(j=>j.email)[0];

            for (let i = 0; i < events.length; i++) {
                if ( events[i].module === action && action === 'Attachment') { //console.log(action);
                    //createEvent
                    for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                        if (JSON.parse(events[i].ActionTodo)[j] === 'createEvent') {
                            let editedItem = {
                                request_id: payloadA.id,
                                startDate: moment().format("YYYY-MM-DD"),
                                isPlannedEvent: 0,
                                isHiddenEvent: 0,
                                standardOfService: "1",
                                AttachmentFileName: oldPayload.AttachmentFileName,
                                RequestStatus: payloadA.RequestStatus,
                                responsiblePerson:store.state.user.first_name,
                                event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                            }
                            axcessApi.postRequest("/event", editedItem );
                        }
                        store.dispatch('request/retriveTemplate').then(r=> {
                            if( JSON.parse( events[i].ActionTodo )[j] === 'sentEmail' ){

                                let template = r.data.filter(x => x.id === events[i].emailTemplate)[0];
                                let content = this.updateContent(template.content, payloadA);
                                let emailContent = {
                                    body: content,
                                    subject: template.name + " Request : " + payloadA.reqCode
                                };
                                if (events[i].toSend === 'requestor') {
                                    emailContent.to = requestorEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipmanager') {
                                    emailContent.to = ATIPOfficerEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipofficer') {
                                    emailContent.to = ATIPManagerEmail;
                                    this.sentEmail(emailContent);
                                }
                            }
                        });
                        //createTask
                        if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                            let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                            let task = {
                                request_id: payloadA.reqCode,
                                title: eventTitle,
                                description: '',
                                created_by: store.state.user.id,
                                status: 'todo'
                            }
                            if (events[i].toSend === 'atipofficer') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }
                            if (events[i].toSend === 'atipmanager') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }

                        }

                    }

                }
                if ( events[i].module === action && action === 'Court') { //console.log(action);
                    //createEvent
                    for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                        if (JSON.parse(events[i].ActionTodo)[j] === 'createEvent') {
                            let editedItem = {
                                request_id: payloadA.id,
                                startDate: moment().format("YYYY-MM-DD"),
                                isPlannedEvent: 0,
                                isHiddenEvent: 0,
                                standardOfService: "1",
                                AttachmentFileName: oldPayload.AttachmentFileName,
                                RequestStatus: payloadA.RequestStatus,
                                responsiblePerson:store.state.user.first_name,
                                event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                            }
                            axcessApi.postRequest("/event", editedItem );
                        }
                        store.dispatch('request/retriveTemplate').then(r=> {
                            if( JSON.parse( events[i].ActionTodo )[j] === 'sentEmail' ){

                                let template = r.data.filter(x => x.id === events[i].emailTemplate)[0];
                                let content = this.updateContent(template.content, payloadA);
                                let emailContent = {
                                    body: content,
                                    subject: template.name + " Request : " + payloadA.reqCode
                                };
                                if (events[i].toSend === 'requestor') {
                                    emailContent.to = requestorEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipmanager') {
                                    emailContent.to = ATIPOfficerEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipofficer') {
                                    emailContent.to = ATIPManagerEmail;
                                    this.sentEmail(emailContent);
                                }
                            }
                        });
                        //createTask
                        if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                            let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                            let task = {
                                request_id: payloadA.reqCode,
                                title: eventTitle,
                                description: '',
                                created_by: store.state.user.id,
                                status: 'todo'
                            }
                            if (events[i].toSend === 'atipofficer') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }
                            if (events[i].toSend === 'atipmanager') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }

                        }

                    }

                }
                //Complaint Observer
                if ( events[i].module === action && (action === 'Complaint' || action === 'Correction')) {
                    //createEvent
                    for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                        if (JSON.parse(events[i].ActionTodo)[j] === 'createEvent') {
                            let editedItem = {
                                request_id: payloadA.id,
                                startDate: moment().format("YYYY-MM-DD"),
                                isPlannedEvent: 0,
                                isHiddenEvent: 0,
                                standardOfService: "1",
                                AttachmentFileName: oldPayload.AttachmentFileName,
                                RequestStatus: payloadA.RequestStatus,
                                responsiblePerson:store.state.user.first_name,
                                event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                            }
                            axcessApi.postRequest("/event", editedItem );
                        }
                        store.dispatch('request/retriveTemplate').then(r=> {
                            if( JSON.parse( events[i].ActionTodo )[j] === 'sentEmail' ){

                                let template = r.data.filter(x => x.id === events[i].emailTemplate)[0];
                                let content = this.updateContent(template.content, payloadA);
                                let emailContent = {
                                    body: content,
                                    subject: template.name + " Request : " + payloadA.reqCode
                                };
                                if (events[i].toSend === 'requestor') {
                                    emailContent.to = requestorEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipmanager') {
                                    emailContent.to = ATIPOfficerEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipofficer') {
                                    emailContent.to = ATIPManagerEmail;
                                    this.sentEmail(emailContent);
                                }
                            }
                        });
                        //createTask
                        if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                            let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                            let task = {
                                request_id: payloadA.reqCode,
                                title: eventTitle,
                                description: '',
                                created_by: store.state.user.id,
                                status: 'todo'
                            }
                            if (events[i].toSend === 'atipofficer') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }
                            if (events[i].toSend === 'atipmanager') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }

                        }

                    }

                }
                //Fees Observer
                if ( events[i].module === action && (action === 'Fees' || action === 'Costs')) {
                    //createEvent
                    for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                        if (JSON.parse(events[i].ActionTodo)[j] === 'createEvent') {
                            let editedItem = {
                                request_id: payloadA.id,
                                startDate: moment().format("YYYY-MM-DD"),
                                isPlannedEvent: 0,
                                isHiddenEvent: 0,
                                standardOfService: "1",
                                AttachmentFileName: oldPayload.AttachmentFileName,
                                RequestStatus: payloadA.RequestStatus,
                                responsiblePerson:store.state.user.first_name,
                                event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                            }
                            axcessApi.postRequest("/event", editedItem );
                        }
                        store.dispatch('request/retriveTemplate').then(r=> {
                            if( JSON.parse( events[i].ActionTodo )[j] === 'sentEmail' ){

                                let template = r.data.filter(x => x.id === events[i].emailTemplate)[0];
                                let content = this.updateContent(template.content, payloadA);
                                let emailContent = {
                                    body: content,
                                    subject: template.name + " Request : " + payloadA.reqCode
                                };
                                if (events[i].toSend === 'requestor') {
                                    emailContent.to = requestorEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipmanager') {
                                    emailContent.to = ATIPOfficerEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipofficer') {
                                    emailContent.to = ATIPManagerEmail;
                                    this.sentEmail(emailContent);
                                }
                            }
                        });
                        //createTask
                        if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                            let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                            let task = {
                                request_id: payloadA.reqCode,
                                title: eventTitle,
                                description: '',
                                created_by: store.state.user.id,
                                status: 'todo'
                            }
                            if (events[i].toSend === 'atipofficer') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }
                            if (events[i].toSend === 'atipmanager') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }

                        }

                    }

                }
            }
            //End Attachment Observer

            // Court Observer
            for (let i = 0; i < events.length; i++) {
                if ( events[i].module === action && action === 'Court') {
                    //createEvent
                    for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                        if (JSON.parse(events[i].ActionTodo)[j] === 'createEvent') {
                            let editedItem = {
                                request_id: payloadA.id,
                                startDate: moment().format("YYYY-MM-DD"),
                                isPlannedEvent: 0,
                                isHiddenEvent: 0,
                                standardOfService: "1",
                                AttachmentFileName: oldPayload.AttachmentFileName,
                                RequestStatus: payloadA.RequestStatus,
                                responsiblePerson:store.state.user.first_name,
                                event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                            }
                            axcessApi.postRequest("/event", editedItem );
                        }
                        store.dispatch('request/retriveTemplate').then(r=> {
                            if( JSON.parse( events[i].ActionTodo )[j] === 'sentEmail' ){

                                let template = r.data.filter(x => x.id === events[i].emailTemplate)[0];
                                let content = this.updateContent(template.content, payloadA);
                                let emailContent = {
                                    body: content,
                                    subject: template.name + " Request : " + payloadA.reqCode
                                };
                                if (events[i].toSend === 'requestor') {
                                    emailContent.to = requestorEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipmanager') {
                                    emailContent.to = ATIPOfficerEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipofficer') {
                                    emailContent.to = ATIPManagerEmail;
                                    this.sentEmail(emailContent);
                                }
                            }
                        });
                        //createTask
                        if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                            let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                            let task = {
                                request_id: payloadA.reqCode,
                                title: eventTitle,
                                description: '',
                                created_by: store.state.user.id,
                                status: 'todo'
                            }
                            if (events[i].toSend === 'atipofficer') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }
                            if (events[i].toSend === 'atipmanager') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }

                        }
                    }
                }
            }
            // Complaint Observer
            for (let i = 0; i < events.length; i++) {
                if ( events[i].module === action && action === 'Complaint') {
                    //createEvent
                    for (let j = 0; j < JSON.parse( events[i].ActionTodo ).length; j++) {
                        if (JSON.parse(events[i].ActionTodo)[j] === 'createEvent') {
                            let editedItem = {
                                request_id: payloadA.id,
                                startDate: moment().format("YYYY-MM-DD"),
                                isPlannedEvent: 0,
                                isHiddenEvent: 0,
                                standardOfService: "1",
                                AttachmentFileName: oldPayload.AttachmentFileName,
                                RequestStatus: payloadA.RequestStatus,
                                responsiblePerson:store.state.user.first_name,
                                event_type:store.getters["configuration/getAllConfiguration"].filter(k=>k.LookUpTableId === events[i].EventId).map(m=>m.LookUpKey)[0]
                            }
                            axcessApi.postRequest("/event", editedItem );
                        }
                        store.dispatch('request/retriveTemplate').then(r=> {
                            if( JSON.parse( events[i].ActionTodo )[j] === 'sentEmail' ){

                                let template = r.data.filter(x => x.id === events[i].emailTemplate)[0];
                                let content = this.updateContent(template.content, payloadA);
                                let emailContent = {
                                    body: content,
                                    subject: template.name + " Request : " + payloadA.reqCode
                                };
                                if (events[i].toSend === 'requestor') {
                                    emailContent.to = requestorEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipmanager') {
                                    emailContent.to = ATIPOfficerEmail;
                                    this.sentEmail(emailContent);
                                }
                                if (events[i].toSend === 'atipofficer') {
                                    emailContent.to = ATIPManagerEmail;
                                    this.sentEmail(emailContent);
                                }
                            }
                        });
                        //createTask
                        if(JSON.parse( events[i].ActionTodo )[j] === 'createTask'){
                            let eventTitle = store.getters["configuration/getAllConfiguration"].filter( filter => filter.LookUpName == 'Event' && filter.LookUpTableId == events[i].EventId ).map( i => localStorage.getItem('language') === 'en' ? i.LookUpStringE : i.LookUpStringF)[0];
                            let task = {
                                request_id: payloadA.reqCode,
                                title: eventTitle,
                                description: '',
                                created_by: store.state.user.id,
                                status: 'todo'
                            }
                            if (events[i].toSend === 'atipofficer') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }
                            if (events[i].toSend === 'atipmanager') {
                                task.assigned_to = payloadA.ATIPOfficerId;
                                store.dispatch('task/postTask',task);
                            }

                        }
                    }
                }
            }
        })
    },
    updateContent(content,request){
        content = content.replace(/\$(\w+.\w+)/g, (_, v) => {
            let string = v.split('.');
            if( string.length >= 1 ){
                if(string[0] === 'address'){
                    return request[string[0]][string[1]];
                }
                else if(string[0] == 'date'){
                    return moment().format("YYYY-MM-DD");
                }
                else if(string[0] == 'user'){
                    return store.state.user[string[1]];
                }else{
                    return request[string[0]];
                }
            }
            else{
                return request[string[0]];
            }
        });
        return content;
    },
    sentEmail(emailContent = {}){
        if(emailContent){
            axcessApi.postRequest('/notification', {
                url: '',
                to: emailContent.to,
                body:emailContent.body,
                subject:emailContent.subject
            }).then(r => r)
        }
    },
    createTask( taskDetail = {}){
        if(taskDetail){
            //console.log(taskDetail);
        }
    }
}
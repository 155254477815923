import axcessApi from "@/plugins/axcessApi";
import LogsHistory from "@/plugins/logs_history";
import moment from 'moment'

const state = {
    token: localStorage.getItem('access_token'),
    task: {
        id: null,
        request_id: null,
        title: '',
        description: '',
        created_by: '',
        assigned_to: '',
        status: '',
        type: '',
        date_created: '',
        due_date: '',
        date_completed: '',
        label: ''
    },
    tasks: [],
    filter: {
        owner: { value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
        title: "",
        status: [{text:localStorage.getItem('language') === 'en'?"Opened":'Ouverte',value:"1"}],
        requestId: [],
        assignee: [],
        type: [],
        dateRecieved: [],
        dueDate: [],
        closeDate: [],
        label: []
    },
    tasksbyStatus: "",
    loading: true,
    user: JSON.parse(localStorage.getItem('user') || null),
}

const getters = {
    tasksFiltered(state) {
        var result = state.tasks;
        if (state.filter.owner.value == '0')
            result = result.filter(a => a.assigned_to == JSON.parse(localStorage.getItem('user')).id);

        if (state.filter.title)
            result = result.filter(item => item.title == state.filter.title);

        if (state.filter.status.length > 0)
            result = result.filter(item => state.filter.status.map(a => a.value).includes(item.status.toString()));

        if (state.filter.label.length > 0)
            result = result.filter(item => JSON.parse(item.label).some(r => state.filter.label.map(x=>x.value).indexOf(r) >= 0));

        if (state.filter.type.length > 0)
            result = result.filter(item => state.filter.type.map(a => a.value).includes(item.type));

        if (state.filter.requestId.length > 0)
            result = result.filter(item => state.filter.requestId.map(a => a.value).includes(item.request_id));

        if (state.filter.assignee.length > 0)
            result = result.filter(item => state.filter.assignee.map(a => a.value).includes(item.assigned_to));

        if (state.filter.dateRecieved.length == 1)
            result = result.filter(item => new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dateRecieved[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateRecieved.length == 2)
            result = result.filter(item =>
                new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dateRecieved[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dateRecieved[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime());

        if (state.filter.dueDate.length == 1)
            result = result.filter(item => new Date(moment(item.due_date).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dueDate[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dueDate.length == 2)
            result = result.filter(item =>
                new Date(moment(item.due_date).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dueDate[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dueDate[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.due_date).format("YYYY-MM-DD")).getTime());


        if (state.filter.closeDate.length == 1)
            result = result.filter(item => new Date(moment(item.date_completed).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.closeDate[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.closeDate.length == 2)
            result = result.filter(item =>
                new Date(moment(item.date_completed).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.closeDate[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.closeDate[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.date_completed).format("YYYY-MM-DD")).getTime());

        return result;



    },

    taskLogsByUser(state) {
        return state.tasks.map(task => task.task_log).flat().filter(log => log.user_id == JSON.parse(localStorage.getItem('user')).id);
    }



}


const actions = {
    postTask(context, payload) {
        payload.status = 1;
        payload.created_by = context.state.user.id;
        payload.task_log = [];
        return axcessApi.postRequest('/alltasks', payload).then((r) => {
            context.commit('addTask', r.data);
            return r;
        })
    },
    updateTask(context, payload) {
        return axcessApi.postRequest('/alltasks', payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((r) => {
            context.commit('updateTask', r.data);
            return r;
        })
    },
    retriveTasks(context) {
        //if(state.tasks.length ) return state.tasks;
        return axcessApi.getRequest('/alltasks').then(r => {
            context.commit('retriveTasks', r.data);
        })
    },
    setCurrentTask(context, payload) {
        context.commit('setCurrentTask', payload);
    },
    filterTasks(context, filters) {
        context.commit('filterTasks', filters)
    },
    postTaskLog(context, payload) {
        return axcessApi.postRequest('tasklog', payload).then(r => {
            context.commit('addTaskLog', r.data);
            return r;
        })
    },
    deleteTaskAttachment(context, payload) {
        return axcessApi.deleteRequest('alltasks/attachment/' + payload.taskid + '/' + payload.attachmentId).then(() => context.commit('updateTaskAttachment', payload));

    },
    clearFilter(context) {
        context.commit('clearFilter');
    },
}


const mutations = {
    updateTaskAttachment(state, payload) {
        state.tasks.filter(i => i.id === payload.taskid).map(j => {
            j.attachment_id = j.attachment_id.split(',').filter(k => k !== payload.attachmentId).join(',')
        })
    },
    addTaskLog(state, payload) {
        state.tasks.filter(i => i.id === payload.task_id)[0]['task_log'].unshift(payload);
    },
    setCurrentTask(state, payload) {
        state.task = payload;
    },
    addTask(state, payload) {
        payload.status = 1;
        payload.comment = '';
        payload.task_log = [];
        let $logs_history= new LogsHistory(state.tasks,payload.id,payload,'tasks');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.tasks.push(payload);
    },
    updateTask(state, payload) {
        let $logs_history= new LogsHistory(state.tasks,payload.id,payload,'tasks');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.tasks = state.tasks.filter(i => i.id != payload.id);
        state.tasks.push(payload);
    },
    retriveTasks(state, tasks) {
        state.tasks = tasks;
        state.loading = false;
    },
    filterTasks(state, filters) {
        Object.assign(state.filter, filters);
    },

    clearFilter(state) {
        let dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
        state.filter ={
            owner:dashboardFilter,
            title: "",
            status: [{text: localStorage.getItem('language') === 'en'?"Opened":'Ouverte',value:"1"}],
            requestId: [],
            assignee: [],
            type: [],
            dateRecieved: [],
            dueDate: [],
            closeDate: [],
            label: []
        }
    },
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axcessApi from "@/plugins/axcessApi";
const state = {
    token: localStorage.getItem('access_token') || null,
    profile: null,
    error: '',
    errored:false,
    eventactions:[]
}
const getters = {
    getSingleEventAction : (state) => (id) => {
        return state.eventactions.filter(EA => EA.EventId === id)
    }
}
const actions = {
    getEventActions(context){
        return axcessApi.getRequest(`/eventactions`).then(r=>{
            context.commit('getMEventActions', r.data.data );
            return r;
        })
    },
    addEventAction(context,payload){
        return axcessApi.postRequest('/eventactions', payload ).then(r=>{
            context.commit( 'addEventAction', r.data );
        })
    },
    updateEventAction(context,payload){
        return axcessApi.patchRequest('/eventactions/' + payload.id , payload.data ).then(r=>{
            context.commit( 'updateEventAction', r.data );
        })
    },
    deleteEventAction(context,payload){
        return axcessApi.deleteRequest('/eventactions/' + payload ).then(()=>{
            context.commit( 'deleteEventAction', payload );
        })
    }
}

const mutations = {
    getMEventActions(state, payload){
        state.eventactions = payload;
    },
    addEventAction(state,payload){
        state.eventactions.push(payload);
    },
    updateEventAction(state,payload){
        state.eventactions = state.eventactions.filter(i=> i.id !== payload.id );
        state.eventactions.push(payload);
    },
    deleteEventAction(state,payload){
        state.eventactions = state.eventactions.filter(i=> i.id !== payload );
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
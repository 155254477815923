import axcessApi from "@/plugins/axcessApi";
import LogsHistory from "@/plugins/logs_history";

const state = {

    loading: true,
    mails:[],
    request:""

}


const getters = {
    mailByRequest : (state) => (id) => {
        let result = state.mails.filter( f => parseInt(f.requestId) == id );
        result.reverse();
        return result;
    }


}

const actions = {

    retriveMails(context) {
        if(state.mails.length ) return state.mails;
        return axcessApi.getRequest('/mails' ).then(r => {
            context.commit('retriveMails', r.data);
        })
    },

    sendEmail(context,payload){
        delete payload.user;
        return axcessApi.postRequest('/sendemail',payload,{
            headers: {
                "Content-Type": "multipart/form-data"
            }}).then((r)=>{
                if(r) context.commit('addEmail',r.data);
            return r;
        })
    },

    setRequest(context, request) {
        context.commit('setRequest', request);
    },


} 


const mutations = {

    retriveMails(state, mails) {
        mails.forEach(mail => { mail.data=JSON.parse(mail.data)}) ;
        state.mails = mails;
        state.loading = false;
    },

    addEmail(state,payload){
        let $logs_history= new LogsHistory(state.mails,payload.id,payload,'email');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        payload.data=JSON.parse(payload.data)
        state.mails.push(payload);
    },


    setRequest(state,payload){
        state.request = payload;
    }


}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
import Vue from 'vue'
import Vuex from 'vuex'
import documents from './modules/documents'
import address from './modules/address'
import request from './modules/request'
import consultation from './modules/consultation'
import correspondence from './modules/correspondence'
import profile from './modules/profile'
import eventTypes from './modules/eventTypes'
import complaint from './modules/complaint'
import court from './modules/court'
import configuration from './modules/configuration'
import attachment from './modules/attachment'
import task from './modules/task'
import role from './modules/role'
import template from './modules/template'
import createConsultation from './modules/createConsultation'
import eventActions from './modules/eventaction'
import email from './modules/email'
import tenants from './modules/tenants'
import taskrules from './modules/taskRules'
import finance from './modules/finance'
import axcessApi from "@/plugins/axcessApi";
import pcoconsultation from "./modules/pcoconsultation";
import LogsHistory from "@/plugins/logs_history";

Vue.use(Vuex)




export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token')|| null,
    rep:null,
    user: null,
    types: [],
    organizations: [],
    users: [],
    filter: 'officer',
    userfilter: {
        role:[],
        status:[],
        searchWord:'',
    },   
    year: 2020,
    finances: [],
    financesTotal: [],  
    events_type: [],
    filterUsers:null, 
  },
  getters: {
    getTypes: (state) => (id) => state.types.filter(i=> i.id === id )[0],
    usersFiltered(state) {
      var result = state.users;
      if (state.userfilter.role.length > 0)
          result = result.filter(item => state.userfilter.role.map(a => a.value).includes(item.role));
      
      if (state.userfilter.status.length > 0)
          result = result.filter(item => state.userfilter.status.map(a => a.value).includes(item.active));

      if (state.userfilter.searchWord!='')
          result = result.filter(user=>user.username.toUpperCase().includes(state.userfilter.searchWord.toUpperCase()) || user.last_name.toUpperCase().includes(state.userfilter.searchWord.toUpperCase())|| user.first_name.toUpperCase().includes(state.userfilter.searchWord.toUpperCase())|| user.email.toUpperCase().includes(state.userfilter.searchWord.toUpperCase()));
 
      return result;
    },
    loggedIn(state) {
      return state.token !== null
    },
    userInfo(state) {
      return state.user;
    },
    getFinances(state) {
      return state.finances;
    },
    getYear(state) {
      return state.year;
    },
    //Getters Finances
    budgetFiltered(state) {
      return state.finances.filter(finance => finance.fiscalYear == state.year && finance.budgetOrExpense === "Budget")
    },
    expenseFiltered(state) {
      return state.finances.filter(finance => finance.fiscalYear == state.year && finance.budgetOrExpense === "Expense")
    },
    budgetTotalFiltered(state) {
      var budgets= []
      state.financesTotal.forEach(item => {
        var budget = parseFloat(item.totalBudget)
        budgets.push(budget)
      })
      return budgets
    },
    expenseTotalFiltered(state) {
      var expenses=[]
      state.financesTotal.forEach(item=> {
        var expense = parseFloat(item.totalExpenses)
        expenses.push(expense)
      })
      return expenses
    },

    isAuthenticated(state) {
      return state.token !== null;
    },
  },
  mutations: {
    updateYear(state, year) {
      state.year = year
    },
    retrieveToken(state, token) {
      state.token = token
    },
    retrieveRep(state, rep) {
      state.rep = rep
    },
    retriveUserInfo(state, user) {
      state.user = user;
      //localStorage.setItem('role',user.userRole);
      //localStorage.setItem("officer", user.id);
    },
    retriveTypes(state, types) {
      state.types = types.data;
    },
    resetTypes(state, types) {
      state.types = types.data;
    },
    retriveOrganizations(state, organizations) {
      state.organizations = organizations.data;
    },
    retriveUsers(state, users) {
      state.users = users;
    },
    retriveFinances(state, finances) {
      state.finances = finances
    },
    retriveFinancesTotal(state, total) {
      state.financesTotal = total
    },
    destroyToken(state) {
      state.token = null
    },
    destroyUser(state) {
      state.user = null
    },

    addFinance(state, finance) {
      state.finances.push(finance)
    },

    updateFinance(state, finance) {
      const index = state.finances.findIndex(item => item.id == finance.id)
      Object.assign(state.finances[index], finance);
    },

    deleteFinance(state, id) {
      const index = state.finances.findIndex(item => item.id == id)
      state.finances.splice(index, 1)
    },

    updateUsers(state,payload){
      let $logs_history= new LogsHistory(state.users,payload.id,payload,'users');
      $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
      state.users = state.users.filter(i=>i.id !== payload.id );
      state.users.push(payload);
      
    },

    addUser(state,payload){
      let $logs_history= new LogsHistory(state.users,payload.id,payload,'users');
      $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
      state.users.push(payload);
    },

    increaseSerial(state, data){
      let index = state.types.findIndex(item => item.id == data );
      state.types[index].last_used_serial_number = state.types[index].last_used_serial_number+1;
    },
    filterUsers(state, filters) {
      Object.assign(state.userfilter, filters);
    },
    clearUsersFilter(state) {
        state.filter = {
            role:[],
            status:[],
            searchWord:'',
        }
    },
  },
  actions: {

    clearUsersFilter(context) {
      context.commit('clearUsersFilter');
    },
    filterUsers(context, filters) {
        context.commit('filterUsers', filters)
    },
    searchUsers(context, filters) {
        context.commit('searchUsers', filters)
    },
    increaseSerial(context,payload){
      context.commit('increaseSerial',payload);
    },
    resetSerialNumber(context){
      return new Promise((resolve, reject) => {
        axcessApi.getRequest('/resettypes',{headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }})
            .then(response => {
              context.commit('resetTypes', response.data);
              resolve(response);
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    retriveToken(context, credentials) {
      return axcessApi.postRequest('/login', {
        username: credentials.username,
        password: credentials.password,
      }).then(r=>{
        if(r.data.two_factor_auth)
        context.commit('retrieveRep', r.data.access_token);
        else
          localStorage.setItem('access_token', r.data.access_token)
      return r})
    },
    retriveAdminToken(context, credentials) {
      return axcessApi.postRequest('/admin/login', {
        username: credentials.username,
        password: credentials.password,
      }).then(r=>{
       localStorage.setItem('access_token', r.data.access_token)
      return r})
    },
    retriveTokenauth(context, credentials) {
      return new Promise((resolve, reject) => {
        axcessApi.postRequest('/googleauth', {
          code:credentials.code
        })
          .then(response => {
            if(response.data=="True"){
              context.commit('retrieveToken',  context.state.rep)
              localStorage.setItem('access_token', context.state.rep)
              context.commit('retrieveRep', null)
              resolve(response)
              //this.$router.push({ name: "dashboard" })
            }
            else
            reject(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    retriveUserInfo(context) {
      return axcessApi.getRequest('currentuser')
        .then(response => {
          context.commit('retriveUserInfo', response.data);
          localStorage.setItem('user', JSON.stringify(response.data))
          return response;
        });
    },
    retriveTypes(context) {
      return new Promise((resolve, reject) => {
        axcessApi.getRequest('/requesttype',{headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }})
          .then(response => {
            context.commit('retriveTypes', response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    retriveOrganizations(context) {
         return new Promise((resolve, reject) => {
          axcessApi.getRequest('/organizations',{headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }})
          .then(response => {
            context.commit('retriveOrganizations', response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    retriveUsers(context) {
      return new Promise((resolve, reject) => {
        axcessApi.getRequest('/user',{headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }})
          .then(response => {
            context.commit('retriveUsers', response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    retriveFinances(context) {
         new Promise((resolve, reject) => {
          axcessApi.getRequest('/finance',{headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }})
        .then(response => {
          context.commit('retriveFinances', response.data.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
    retriveFinancesTotal(context, year) {
    
      new Promise((resolve, reject) => {
        axcessApi.getRequest('/finance/totals/' + year,{headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }})
        .then(response => {
          context.commit('retriveFinancesTotal', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },  
    updateUser(context,payload){
      return axcessApi.patchRequest("/user/" + payload.id,payload).then((r)=>{
           context.commit('updateUsers',r.data);
           JSON.parse(localStorage.getItem('user')).id==payload.id ? context.dispatch('retriveUserInfo',null ,{ root:true }):"";
          return r;
      })
    },
    addUser(context,payload){
      return axcessApi.postRequest("/register",payload).then((r)=>{
           context.commit('addUser',r.data);
          return r;
      })
    },
    assignRoleUser(context,payload){
      return axcessApi.postRequest('/assignroleuser/'+payload.role+'/'+payload.user).then((r)=>{
        JSON.parse(localStorage.getItem('user')).id==payload.user? context.dispatch('retriveUserInfo',null ,{ root:true }):"";
        context.commit('updateUsers',r.data);
        return r;
      })
    },

    updateYear(context, year) {
      context.commit('updateYear', year)
    },

    addFinance(context, finance) {
      context.commit('addFinance', finance)
    },
    updateFinance(context, finance) {
      context.commit('updateFinance', finance)
    },
    deleteFinance(context, id) {
      context.commit('deleteFinance', id)
    },

    register(context, data) {
      return new Promise((resolve, reject) => {
        axcessApi.postRequest('/register', {
          username: data.username,
          email: data.email,
          password: data.password,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    destroyToken(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axcessApi.postRequest('/logout')
            .then(response => {
              localStorage.removeItem('access_token')
              context.commit('destroyToken')
              context.commit('destroyUser')
              resolve(response)
            })
            .catch(error => {
              localStorage.removeItem('access_token')
              context.commit('destroyUser')
              context.commit('destroyToken')
              reject(error)
            })
        })
      }
    },       
  },

  modules: {
    documents,
    address,
    request,
    consultation,
    profile,
    eventTypes,
    complaint,
    court,
    configuration,
    correspondence,
    attachment,
    createConsultation,
    eventActions,
    task,
    template,
    role,
    email,
    taskrules,
    tenants,
    finance,
    pcoconsultation
  }
})

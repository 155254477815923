export default {
    hasAccess(name){
        let permissions= JSON.parse(localStorage.getItem('user') || null)?.permission.map(pr => pr.name);
        switch (name) {
            case "request":
              return permissions?.includes("Requests.Read");
             case "users":
                return permissions?.includes("AdminMenu.Read") && permissions?.includes("Users.Read") ;
             case "manageroles":
              return permissions?.includes("AdminMenu.Read") && permissions?.includes("Permissions.Read") ;
            case "address":
              return permissions?.includes("Contacts.Read");
            default:
              return true;
          }
    },
}

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {en, fr},
        current: 'en'
    },
    theme: { themes: {
        dark: {
          white:"#020202",
          gray:"#040404",
          customlightgray:"#060606",
          customoffwhite:"#090909",
        },
        light: {
          white:"#FFFFFF",
          gray:"#f0f0f0",
          customlightgray:"#f8f8f8",
          customoffwhite:"#fdfdfd",
        }
      },
      dark: false },
      pinned:{},
      full:{},
      muted:{},
      multitask:{},
      tasksRows:5,
      requestsRows:10,
      dashboardFilter:null,
});

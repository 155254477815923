import axcessApi from "@/plugins/axcessApi";
import eventObserver from "@/plugins/eventObserver";
import moment from "moment/min/moment-with-locales";
import LogsHistory from "@/plugins/logs_history";

const state = {
    token: localStorage.getItem('access_token'),
    requestAttachment: {
        id:'',
        ReqIdentity:'',
        OrgId:'',
        RequestId:'',
        AttachmentFileName:[],
        AttachmentDescription:'',
        AttachmentNote:'',
        AttachmentDate:moment().format("YYYY-MM-DD"),
        UpdatedBy:''
    },
    attachments: [],
    loading: true,
    user: JSON.parse(localStorage.getItem('user') || null),
}

const getters = {
    getAttachments : (state) => (id) => {
        return state.attachments.filter( f => f.RequestId == id )
    }
}

const actions = {
    retriveRequestAttachment(context, id) {
        return axcessApi.getRequest('/attachment/request/' + id ).then(r => {
            if(r.data) context.commit('retriveAttachments', r.data)
            return r
        } )
    },
    retriveAttachments(context) {
        return axcessApi.getRequest('/attachment' ).then(r => {
            if(r.data)
                context.commit('retriveAttachments', r.data)
            return r;
        })
    },
    deleteAttachment(context, attachmentId ){
        return axcessApi.deleteRequest( `/attachment/`+ attachmentId).then(()=>{
            context.commit('deleteAttachment', attachmentId );
        })
    },

    updateAttachment(context, request) {
        return axcessApi.patchRequest(`/attachment/${request.id}`,
            request ).then(r=> {
            eventObserver.eventObserver('Attachment', r.data);
            context.commit('updateAttachement', r.data)
            return r;
        })
    },

    addAttachment(context, request) {
        const formData = new FormData();
        let filename = request.filename ? request.filename : '';
        console.log(filename);
        formData.append('AttachmentFileName', request.AttachmentFileName , filename);
        formData.append('ReqIdentity', request.ReqIdentity)
        formData.append('OrgId', request.OrgId)
        formData.append('RequestId', request.RequestId)
        formData.append('AttachmentDescription', request.AttachmentDescription)
        formData.append('AttachmentNote', request.AttachmentNote)
        formData.append('AttachmentDate', request.AttachmentDate)
        formData.append('UpdatedBy', request.UpdatedBy)
        return axcessApi.postRequest("/attachment", formData).then(r=>{
            r ? eventObserver.eventObserver('Attachment', r.data) : '';
            r ? context.commit('addAttachment', r.data ) : '';
            return r;
        })
    },
    setAttachment(context){
        context.commit('setAttachment');
    },
    async downloadAttachment(context, attachment){
        return await axcessApi.getRequest('/attachment/download/' + attachment.id,{responseType: 'blob'} ).then(res => {
            const url = window.URL
                .createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = attachment.AttachmentFileName
            link.target = '_blank'
            link.click();
            return res;
        } )
    },
    directAttachDelete(context, attachmentid){
        context.commit('deleteAttachment', attachmentid );
    }
}

const mutations = {
    retriveAttachments(state, attachments) {
        state.attachments = attachments;
        state.loading = false;
    },
    addAttachment( state, attachment ){
        let $logs_history= new LogsHistory(state.attachments,attachment.id,attachment,'attachments');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.attachments.push(attachment);
        state.requestAttachment = {
            id:'',
            ReqIdentity:'',
            OrgId:'',
            RequestId:'',
            AttachmentFileName:[],
            AttachmentDescription:'',
            AttachmentNote:'',
            AttachmentDate:'',
            UpdatedBy:''
        }
    },
    updateAttachement(state, attachment){
        let $logs_history= new LogsHistory(state.attachments,attachment.id,attachment,'attachments');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.attachments = state.attachments.filter(item=> item.id != attachment.id );
        state.attachments.push(attachment);
    },
    deleteAttachment(state, attachmentId ){
        state.attachments = state.attachments.filter(item=> item.id != attachmentId );
    },
    setAttachment(){
        state.requestAttachment = {
            id:'',
            ReqIdentity:'',
            OrgId:'',
            RequestId:'',
            AttachmentFileName:[],
            AttachmentDescription:'',
            AttachmentNote:'',
            AttachmentDate:moment().format("YYYY-MM-DD"),
            UpdatedBy:''
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
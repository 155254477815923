import axcessApi from "@/plugins/axcessApi";
import LogsHistory from "@/plugins/logs_history";
import moment from 'moment'

//import eventObserver from "@/plugins/eventObserver";


const state = {
    token: localStorage.getItem('access_token'),
    complaints: [],
    status: 'active',
    loading: true,
    filter: {
        commissionerFileNumber: "",
        complaintReason:[],
        complaintText:"",
        dateReceived:[],
        dateClosed:[],
        dateOfFinding:[],
        complainant:[],
        requestId:[],
        complaintResult:[],
        complaintFinding:[],
    },
}


const getters = {
    complaintsFiltered(state) {
        var result = state.complaints;
        
        if (state.filter.commissionerFileNumber)
            result = result.filter(item => item.commissionerFileNumber == state.filter.commissionerFileNumber);
        if (state.filter.complaintText)
            result = result.filter(item => item.complaintText == state.filter.complaintText);

        if (state.filter.complaintReason.length > 0)
            result = result.filter(item => state.filter.complaintReason.map(a => a.value).includes(item.complaintReason.toString()));

        if (state.filter.complaintResult.length > 0)
            result = result.filter(item => state.filter.complaintResult.map(a => a.value).includes(item.complaintResult.toString()));

        if (state.filter.complaintFinding.length > 0)
            result = result.filter(item => state.filter.complaintFinding.map(a => a.value).includes(item.complaintFinding.toString()));

        if (state.filter.requestId.length > 0)
            result = result.filter(item => state.filter.requestId.map(a => a.value).includes(item.request_id));

        if (state.filter.complainant.length > 0)
            result = result.filter(item => state.filter.complainant.map(a => a.value).includes(item.complainant));

        if (state.filter.dateReceived.length == 1)
            result = result.filter(item => new Date(moment(item.dateReceived).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dateReceived[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateReceived.length == 2)
            result = result.filter(item =>
                new Date(moment(item.dateReceived).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dateReceived[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dateReceived[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.dateReceived).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateOfFinding.length == 1)
            result = result.filter(item => new Date(moment(item.dateOfFinding).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dateOfFinding[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateOfFinding.length == 2)
            result = result.filter(item =>
                new Date(moment(item.dateOfFinding).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dateOfFinding[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dateOfFinding[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.dateOfFinding).format("YYYY-MM-DD")).getTime());


        if (state.filter.dateClosed.length == 1)
            result = result.filter(item => new Date(moment(item.dateClosed).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.dateClosed[0]).format("YYYY-MM-DD")).getTime());

        if (state.filter.dateClosed.length == 2)
            result = result.filter(item =>
                new Date(moment(item.dateClosed).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.dateClosed[0]).format("YYYY-MM-DD")).getTime() &&
                new Date(moment(state.filter.dateClosed[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.dateClosed).format("YYYY-MM-DD")).getTime());
        return result;



    },
}



const actions = {

    retriveComplaints(context) {
        return axcessApi.getRequest('/reqcomplaint').then(r => {
            if (r.data)
                context.commit('retriveComplaints', r.data)
            return r
        })
    },

    filterComplaints(context, filters) {
        context.commit('filterComplaints', filters)
    },
    createComplaint(context, payload) {
/*         return axcessApi.postRequest("/reqcomplaint", complaint).then(r => {
            eventObserver.eventObserver('Complaint', r.data);
            return r;
        }) */
        return axcessApi.postRequest('/reqcomplaint',payload).then((r)=>{
            context.commit('addComplaint',r.data);
            return r;
        })

    },

    updateComplaint(context, complaint) {
        return axcessApi.patchRequest("/reqcomplaint/" + complaint.id, complaint).then(r => {
            context.commit('updateComplaint', r.data);
            return r;
        })

    },

    clearComplaintsFilter(context) {
        context.commit('clearComplaintsFilter');
    },
}

const mutations = {
    updateComplaint(state,payload){
        let $logs_history= new LogsHistory(state.complaints,payload.id,payload,'complaint');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.complaints = state.complaints.filter(f => f.id !== payload.id);
        state.complaints.push(payload);
    },
    addComplaint(state,payload){
        let $logs_history= new LogsHistory(state.complaints,payload.id,payload,'complaint');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.complaints.push(payload);
    },
    retriveComplaints(state, complaints) {
        state.complaints = complaints.data;
        state.loading = false;
    }, 
    filterComplaints(state, filters) {
        Object.assign(state.filter, filters);
    },
    clearComplaintsFilter(state) {
        state.filter = {
            commissionerFileNumber: "",
            complaintReason:[],
            complaintText:"",
            dateReceived:[],
            dateClosed:[],
            dateOfFinding:[],
            complainant:[],
            requestId:[],
            complaintResult:[],
            complaintFinding:[],
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
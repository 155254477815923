import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('../views/Dashboard'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:true},
          ]
        },
      },
      {
        name: 'consultation',
        path: '/consultation',
        component: () => import('../views/AllConsultationTable'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Consultations',href:'/consultation',disabled:true},
          ]
        }
      },
      {
        name: 'invoice',
        path: '/invoice/:id',
        component: () => import('../views/invoice'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:true},
            {text:'Invoice',href:'/invoice/:id',disabled:true},
          ]
        }
      },
      {
        name: 'complaint',
        path: '/complaint',
        component: () => import('../views/Complaint'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Complaints',href:'/complaint',disabled:true},
          ]
        }
      },
      {
        name: 'about',
        path: '/about',
        component: () => import('../views/about'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'About',href:'/about',disabled:true},
          ]
        }
      },
      {
        name: 'court',
        path: '/court',
        component: () => import('../views/Court'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Court',href:'/court',disabled:true},
          ]
        }
      },
      {
        name: 'correspondence',
        path: '/correspondence',
        component: () => import('../views/Correspondence'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Correspondence',href:'/correspondence',disabled:true},
          ]
        }
      },
      {
        path: '/request/over/:id',
        name: 'requests',
        component: () => import('../views/RequestInfo'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Request',href:'/request/over/:id',disabled:true},
          ]
        },
      },
      {
        path: '/request/new',
        name: 'addRequest',
        component: () => import('../views/NewRequest'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Create Request',href:'/request/new',disabled:true},
          ]
        },
      },
      {
        path: '/task/new/:id?',
        name: 'addTask',
        component: () => import('../views/AddTaskInfo'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Create Task',href:'/task/new/:id?',disabled:true},
          ]
        },
      },
      {
        path: '/address',
        name: 'address',
        component: () => import('../views/Address'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Addresses',href:'/address',disabled:true},
          ]
        },
      },
      {
        path: '/finance',
        name: 'finance',
        component: () => import('../views/Finance'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Finance',href:'/finance',disabled:true},
          ]
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Profile',href:'/profile',disabled:true},
          ]
        }
      },
      {
        name: 'reqType',
        path: '/reqtypes',
        component: () => import('../views/RequestType'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Request Types',href:'/reqtypes',disabled:true},
          ]
        }
      },
      {
        name: 'users',
        path: '/users',
        component: () => import('../views/Users'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Users',href:'/users',disabled:true},
          ]
        }
      },
      {
        name: 'manageroles',
        path: '/manageroles',
        component: () => import('../views/Roles'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Roles',href:'/manageroles',disabled:true},
          ]
        }
      },
      {
        name: 'purgerequests',
        path: '/purgerequests',
        component: () => import('../views/PurgeRequests'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Purge Requests',href:'/purgerequests',disabled:true},
          ]
        }
      },
      {
        name: 'taskrules',
        path: '/taskrules',
        component: () => import('../views/TaskRule'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Task Rules',href:'/taskrules',disabled:true},
          ]
        }
      },
      {
        name: 'setupholidays',
        path: '/holiday',
        component: () => import('../views/Holidays'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Holidays',href:'/holiday',disabled:true},
          ]
        }
      },
      {
        name: 'managefields',
        path: '/fieldmanager',
        component: () => import('../views/FieldManager'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Field Manager',href:'/fieldmanager',disabled:true},
          ]
        }
      },
      {
        name: 'managesectors',
        path: '/sectormanager',
        component: () => import('../views/SectorManager'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Sector Manager',href:'/sectormanager',disabled:true},
          ]
        }
      },
      {
        name: 'activities',
        path: '/activities',
        component: () => import('../views/Activities'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Activities',href:'/activities',disabled:true},
          ]
        }
      },
      {
        name: 'configuration',
        path: '/configuration',
        component: () => import('../views/Configuration'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Configuration',href:'/configuration',disabled:true},
          ]
        }
      },
      {
        name: 'template',
        path: '/template',
        component: () => import('../views/Templates'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Templates',href:'/template',disabled:true},
          ]
        }
      },
      {
        name: 'audittrail',
        path: '/audittrail',
        component: () => import('../views/AuditTrail'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Audit Trail',href:'/audittrail',disabled:true},
          ]
        }
      },
      {
        name: 'logshistory',
        path: '/logshistory',
        component: () => import('../views/LogsHistory'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Logs History',href:'/logshistory',disabled:true},
          ]
        }
      },
      {
        name: 'atipReport',
        path: '/report/atip',
        component: () => import('../views/AtipReport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'ATIP Report',href:'/report/atip',disabled:true},
          ]
        }
      },
      {
        name: 'complaintReport',
        path: '/report/complaint',
        component: () => import('../views/ComplaintReport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Complaint Report',href:'/report/complaint',disabled:true},
          ]
        }
      },
      {
        name: 'consultationReport',
        path: '/report/consultation',
        component: () => import('../views/consultation'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Consultation Report',href:'/report/consultation',disabled:true},
          ]
        }
      },
      {
        name: 'courtReport',
        path: '/report/court',
        component: () => import('../views/courtReport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Court Report',href:'/report/court',disabled:true},
          ]
        }
      }, 
      {
        path: '/request/export/:id',
        name: 'exportrequest',
        component: () => import('../views/RequestExport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Export',href:'/request/export/:id',disabled:true},
          ]
        },
      },
      {
        name: 'snapshotReport',
        path: '/report/snapshot',
        component: () => import('../views/snapshotReport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Snapshot Report',href:'/report/snapshot',disabled:true},
          ]
        }
      },
      {
        name: 'tbsReport',
        path: '/report/tbs',
        component: () => import('../views/TbsReport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'TBS',href:'/report/tbs',disabled:true},
          ]
        }
        
      },
      {
        name: 'tasks',
        path: '/task/:id',
        component: () => import('../views/TaskInfo'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Task',href:'/task/:id',disabled:true},
          ]
        }
      },
      {
        name: 'RequestDashboard',
        path: '/report/RequestDashboard',
        component: () => import('../views/RequestDashboard'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Request Dashboard Report',href:'/report/RequestDashboard',disabled:true},
          ]
        }
        
      }, 
      {
        name: 'TimeAnalysis',
        path: '/report/TimeAnalysis',
        component: () => import('../views/TimeAnalysis'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Time Analysis report',href:'/report/TimeAnalysis',disabled:true},
          ]
        }
        
      }, 
      {
        name: 'WeeklyReport',
        path: '/report/WeeklyReport',
        component: () => import('../views/WeeklyReport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Weekly Report',href:'/report/WeeklyReport',disabled:true},
          ]
        }
        
      }, 
      {
        name: 'WorkloadReport',
        path: '/report/WorkloadReport',
        component: () => import('../views/WorkloadReport'),
        meta: {
          requiresAuth: true,
          bradcrumb:[
            {text:'Home',href:'/',disabled:false},
            {text:'Workload Report',href:'/report/WorkloadReport',disabled:true},
          ]
        }
        
      }, 
      
      
    ]
  },
  {
    path: '/login/',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../layouts/Vanilla.vue'),
    children: [
      {
        name: 'login',
        path: '/login/',
        component: () => import('../views/Login'),
        meta: {
          requiresVisitors: true
        },
      },
    ]
  },
  {
    path: '/admin/login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../layouts/Vanilla.vue'),
    children: [
      {
        name: 'adminlogin',
        path: '/admin/login',
        component: () => import('../views/AdminLogin'),
      },
    ]
  },
  {
    path: '/admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../layouts/Vanilla.vue'),
    children: [
      {
        name: 'admin',
        path: '/admin',
        component: () => import('../views/Admin'),
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
  {
    name: 'editor',
    path: '/editor/:request_id',
    component: () => import('../views/EditorViewer'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'partiner',
    path: '/partiner/:uuid',
    component: () => import('../views/ValidateLink'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'consult',
    path: '/consult/:id',
    component: () => import('../views/ConsultDetail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'bored',
    path: '/bored',
    component: () => import('../views/Bored'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'error',
    path: '*',
    component: () => import('../views/ErrorPage'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});



export default router;

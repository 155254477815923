import moment from 'moment';
import axcessApi from "@/plugins/axcessApi";


const state = {
    token: localStorage.getItem('access_token') || null,
    consult_token: localStorage.getItem('access_token') || null,
    user: JSON.parse(localStorage.getItem('user') || null),
    consultations: [],
    consult: null,
    consultDoc: null,
    item_id: null,
    comments: [],
    preview: '',
    loading: false,
    releasedoc:[],
    filter: {
      doc_file_name: "",
      contact_name: "",
      created_at:[],
      sent_out_date:[],
      req_code:[],
    }
}

const getters = {
    getConsultDoc : (state) => (id) => {
      let result = state.consultations;
      if(id)
        result=result.filter( f => f.request_id === id );
      if (state.filter.doc_file_name)
          result = result.filter(item => item.doc_file_name == state.filter.doc_file_name);

      if (state.filter.contact_name)
          result = result.filter(item => item.contact_name == state.filter.contact_name); 

      if (state.filter.req_code.length > 0)
          result = result.filter(item => state.filter.req_code.map(a => a.value).includes(item.req_code));
      
      if (state.filter.created_at.length == 1)
      result = result.filter(item => new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.created_at[0]).format("YYYY-MM-DD")).getTime());

      if (state.filter.created_at.length == 2)
          result = result.filter(item =>
              new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.created_at[0]).format("YYYY-MM-DD")).getTime() &&
              new Date(moment(state.filter.created_at[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.created_at).format("YYYY-MM-DD")).getTime());

      if (state.filter.sent_out_date.length == 1)
          result = result.filter(item => new Date(moment(item.sent_out_date).format("YYYY-MM-DD")).getTime() == new Date(moment(state.filter.sent_out_date[0]).format("YYYY-MM-DD")).getTime());

      if (state.filter.sent_out_date.length == 2)
          result = result.filter(item =>
              new Date(moment(item.sent_out_date).format("YYYY-MM-DD")).getTime() >= new Date(moment(state.filter.sent_out_date[0]).format("YYYY-MM-DD")).getTime() &&
              new Date(moment(state.filter.sent_out_date[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.sent_out_date).format("YYYY-MM-DD")).getTime());

      return result || [];
  },
  getConsultInfo(state) {
    if(state.consult) {
      return state.consult.filter(item => item.doc_id === state.item_id);
    }
  },

  getUserConsultationList(state) {
    if(state.user && state.user.id) {
      if(state.consultations.length )
      return state.consultations.filter(item => item.created_by === state.user.id );
    }
  },

  filterconsult(state){
    if(state.consult) {
      var resArr = [];
      state.consult.sort((a,b)=> b.id - a.id);
      state.consult.sort((a,b)=> moment(b.sent_out_date,'DD-MM-YYYY').unix() - moment(a.sent_out_date,'DD-MM-YYYY').unix());
      state.consult.forEach(function(item){
        var i = resArr.findIndex(x => x.request_id == item.request_id);
        if(i <= -1){
          resArr.push(item);
        }
      });
      return resArr;
    }
  }
    
}

const actions = {
    validateLink(context, data) {
      localStorage.setItem('consult_token', data.access_token);
      context.commit('validateLink', data.consults);
    },

      retrieveDocInfo(context, id) {
      
        return axcessApi.getRequest('/document/' + id ).then(r => {
            if(r.data) context.commit('retrieveDocInfo', r.data)
            return r;
        } )
      },
      retrieveConsultations(context) {
        state.loading = true;
          return axcessApi.getRequest('reqconsult' ).then(r => {
              if(r.data) context.commit('retrieveConsultations', r.data.data)
              return r;
          } )
      },
      filterConsultations(context,filters) {
        context.commit('filterConsultations', filters)
      },
      clearConsultationsFilter(context) {
        context.commit('clearConsultationsFilter');
      },
      loadReleaseDoc(context) {
        state.loading = true;
          return axcessApi.getRequest('releasedoc/'+this.state.request.requestItem.id ).then(r => {
              context.commit('retrieveReleasedoc', r.data)
              state.loading = false;
              return r;
          })
      },
      updateSentdate(context, payload){
             let data = moment().format("YYYY-MM-DD");
        return axcessApi.patchRequest('reqconsult/'+ payload, {date:data} ).then(r=>{
            context.commit('updateConsultations', r.data)
            state.loading = false;
            return r;
        })
      },
      retrieveUserConsultations(context) {
          return axcessApi.getRequest('reqconsult' ).then(r => {
              if(r.data) context.commit('retrieveConsultations', r.data.data)
              return r;
          } )
      },

      retrieveComments(context) {
        return axcessApi.getRequest('consultantcomment' ).then(r => {
            if(r.data) context.commit('retrieveComments', r.data.data)
            return r;
        } )
      },

    postComment(context, commentData) {
        return axcessApi.postRequest('consultantcomment', commentData).then(r=>{
            context.commit('postComment', r.data)
            return r;
        })
      },

      postUserComment(context, commentData) {
          return axcessApi.postRequest('usercomment', commentData).then(r=>{
              context.commit('postComment', r.data)
              return r;
          })
      },

      updateComment(context, comment) {
          return axcessApi.postRequest(`consultantcomment/${comment.id}`, {
              box_id: comment.box_id,
              comment_from_agency: state.consult[0].agency_id,
              comment: comment.comment
          }).then(r=>{
              context.commit('updateComment', r.data)
              return r;
          })
      },

      updateUserComment(context, comment) {
          return axcessApi.patchRequest(`usercomment/${comment.id}`, {
              box_id: comment.box_id,
              comment: comment.comment
          } ).then(r=>{
              context.commit('updateComment', r.data)
              return r;
          })
      },
      
      deleteComment(context, id) {
        return axcessApi.deleteRequest(`consultantcomment/${id}`).then(r=>{
            context.commit('deleteComment', id)
            return r;
        })
      },

      deleteReqConsult(context, id) {
        return axcessApi.deleteRequest(`reqconsult/${id}`).then(r=>{
            context.commit('deleteReqConsult', id)
            return r;
        })
      },

      setItemId(context, id) {
        context.commit('setItemId', id);
      }, 

      setPreview(context, preview) {
        context.commit('setPreview', preview);
      }
}

const mutations = {
    clearConsultationsFilter(state) {
        state.filter = {
            doc_file_name: "",
            contact_name: "",
            created_at:[],
            sent_out_date:[],
            req_code:[],
        }
    },
    filterConsultations(state, filters) {
      Object.assign(state.filter, filters);
    },
    retrieveReleasedoc(state, consult) {
        state.releasedoc = consult;
    },
    validateLink(state, consult) {
        state.consult = consult;
    },

    retrieveDocInfo(state, doc) {
      state.consultDoc = doc;
    },

    retrieveConsultations(state, consultations) {
      state.consultations = consultations;
    },

    retrieveComments(state, comments) {
      state.comments = comments;
    },

    postComment(state, comment) {
      state.comments.push(comment);
    },

    updateComment(state, comment) {
      const index = state.comments.findIndex(item => item.id == comment.id);
      state.comments[index] = comment;
    },

    deleteComment(state, id) {
      const index = state.comments.findIndex(item => item.id == id)
      state.comments.splice(index, 1);
    },

    deleteReqConsult(state, id) {
      const index = state.consultations.findIndex(item => item.id == id)
      state.consultations.splice(index, 1);
    },

    setItemId(state, id) {
      state.item_id = id;
    },

    setPreview(state, preview) {
      state.preview = preview;
    },
    updateConsultations(state,updatesent){ console.log(updatesent);
      state.consultations.forEach(item=>{
        if(item.id == updatesent.id ){
          item.sent_out_date = moment(updatesent.sent_out_date,'YYYY-MM-DD')
        }
      })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
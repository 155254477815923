import axios from 'axios'
import range from 'lodash/range'
import printJS from 'print-js';

import jsPDF from "jspdf";
import pdfjs from "pdfjs-dist";
import axcessApi from "@/plugins/axcessApi";
import moment from "moment";

const state = {
    token: localStorage.getItem('access_token') || null,
    docs: [],
    document: null,
    file: null,
    docId: null,
    preview: undefined,
    extension: '',
    numPages: 0,
    mode: 'view',
    currentPage: 1,
    isDrawing: false,
    isNote: false,
    markupType: 'Rectangle',
    error: '',
    cerror: '',
    errored: false,
    alert: false,
    calert: false,
    showClauses: true,
    rectangleStyle: "transparent",
    boxColor: "#23707696",
    boxBorder: 'none',
    loading: false,
    pagePdf:[],
    printdoc:'',
    processState: 0,
    print:false,
    markedDocs:[],
    selectedDocs:[],
    conDocname:"",
    conLoading:false,
    markedPages:1,
    pageStatus:[],
    scale:2,
    notes:[],
    layerboxes:[], 
    layers:[], 
    documents:[],
    enhancement:{
        brightness:100,
        contrast:100
    }
}

const getters = {
    getRegDocs : (state) => (id) => {
        return state.documents.filter( f => f.request_id === parseInt(id) && f.status !== "S" ).sort( (a,b) => a.sortKey - b.sortKey)
    },
    getUnRegDocs : (state) => (id) => {
        return state.documents.filter( f => f.request_id == parseInt(id) && f.status == "S").sort( (a,b) => a.sortKey - b.sortKey)
    },
    getLayerBoxes(state){
      return state.layerboxes;
    },
    getLayers(state){
      return state.layers;
    },
    getDocuments(state){
      return state.documents;
    },
    getStatistics(state){
        //let pages,excluded,exemptc,duplicateCount,notRel='';
        let items = [];
        if(state.documents.length){
            items = state.documents.filter(doc => doc.status !== "S" && doc.layers && doc.layers.length > 0).map(item=>item.layers[0]);
        }else{
            items = [];
        }
          var pages=0,
          duplicateCount=0,
          notRel=0,
          excluded=0,
          exemptc=0;
          //For Total Reviewed Pages
          items.forEach(el => {
            pages += el.boxes.map(a=> a.pageNum ).filter((value, index, self)=>self.indexOf(value) === index).length;
          });
          //For Duplicate
          items.forEach(el => {
            duplicateCount += el.boxes.filter(a=> a.clausesUsed[0] == 'Duplicate - Copie' ).map(a=> a.pageNum ).filter((value, index, self)=>self.indexOf(value) === index).length;
          });
          //For notrelevant
          items.forEach(el => {
            notRel += el.boxes.filter(a=> a.clausesUsed[0] == 'Not rel - Non pert' ).map(a=> a.pageNum ).filter((value, index, self)=>self.indexOf(value) === index).length;
          });


          //For Excluded
          items.forEach(el => {
            excluded += el.boxes.filter(a=> a.clausesUsed.includes("Excluded - Exclu")&& a.clausesUsed.length>1).map(a=> a.pageNum ).filter((value, index, self)=>self.indexOf(value) === index).length;
            exemptc += el.boxes.filter(a=> a.clausesUsed.includes("Excluded - Exclu")&& a.clausesUsed.length==1 ).map(a=> a.pageNum ).filter((value, index, self)=>self.indexOf(value) === index).length;
          });
      return {
        produced: state.documents.length,
        released:state.documents.map(item=> item.pageCount !== null? item.pageCount:0 ).reduce((a,b)=>parseInt(a)+parseInt(b),0),
        reviewed: pages,
        processed: state.documents.filter(doc => doc.status !== "S").length,
          pagesprocessed: state.documents.filter(doc => doc.status !== "S").map(item=>item.pageCount).reduce((a,b)=>parseInt(a)+parseInt(b),0),
        excluded: excluded,
        exempt: exemptc,
        duplicate: duplicateCount,
        notrelevant: notRel,
          notRegistered:state.documents.filter(doc => doc.status === "S").map(item=>item.pageCount).reduce((a,b)=>parseInt(a)+parseInt(b),0),
        registered: state.documents.filter(doc => doc.status !== "S").map(item=>item.pageCount).reduce((a,b)=>parseInt(a)+parseInt(b),0)
      }
    },
    getNotesByPage(state){
        return state.notes.filter(doc => doc.docId === state.doc_id && doc.page_num === state.currentPage )
    },
    getDocumentsByRequest(state) {
        return state.documents.filter(doc => doc.status !== "S")
    },

    getPreview(state) {
      return state.preview
    },

    getBoxesByPage(state) {
      const boxes = []
      if(state.document && state.document.layers) {
        state.document.layers.forEach(item => {
          item.boxes.forEach(box => {
            if(box.pageNum == state.currentPage) {
              boxes.push(box)
            }
          })
        });
      }
      return boxes
    },

    getBoxesAll(state) {
      const boxes = []
      if(state.document && state.document.layers) {
        state.document.layers.forEach(item => {
          item.boxes.forEach(box => {
            boxes.push(box)
          })
        });
      }
      return boxes
    },
}

const actions = {
    setEnhancement(context, data){
        context.commit('setEnhancement', data);
    },
    setWatermarkText(context, content){
        context.commit('setWatermarkText',content);
    },
    retrieveDocuments(context) {
        return axcessApi.getRequest('/document' ).then(r => {
            if(r.data) context.commit('retrieveDocuments', r.data)
            return r;
        })
    },
    retrieveLayerBoxes(context) {
        return axcessApi.getRequest('/box' ).then(r => {
            if(r.data) context.commit('retrieveLayerBoxes', r.data)
            return r;
        })
    },
    retrieveLayers(context) {
        return axcessApi.getRequest('/layer' ).then(r => {
            if(r.data) context.commit('retrieveLayers', r.data)
            return r;
        })
    },
    getNotesByPage(context,notes){
        return axcessApi.getRequest('/pagenotes/doc_id/'+notes.doc_id+'/page_num/'+notes.page_num).then(r=>{
           if( r.data ) context.commit('setNotesByPage', r.data );
        });
    },
    setNotesByPage(context,notes){
        return axcessApi.postRequest('pagenotes', notes).then(r=>{
           if( r.data ) context.commit('setNotesByPage', r.data );
        });
    },
    updateNotesByPage( context, notes ){
        return axcessApi.patchRequest('pagenotes/'+notes.id, notes).then(r=>{
            if( r.data ) context.commit('updateNotesByPage', r.data[0] );
        });
    },
    removeNote(context, note ){
        return axcessApi.deleteRequest('pagenotes/'+ note).then( ()=>{
            context.commit('deleteNote',note);
        })
    },
    async setPageStatus( context, pageStatus ){
        let pageDetail = [];
        return axcessApi.getRequest('/pagestatus/'+pageStatus.request_id+'/doc_id/'+pageStatus.id +'/pagenum/'+pageStatus.currentPage ).then(r => {
            if(r.data.length){
                pageDetail = r.data[0];
            }else{
                pageDetail = [];
            }
            context.commit('setPageStatus', pageDetail );
            return r;
        })
    },
    getScale({commit}){
        commit('getScale',2);
    },
      printConsultant(context,request_id){
        state.print = true;
        return axcessApi.getRequest('/print/'+request_id,
        {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        } ).then( r => {
            const url = window.URL.createObjectURL(new Blob([r.data]));
            printJS(url);
            state.print = false;
            context.commit('printdoc', r)
            return r;
        })
      },
      retrieveDocumentInfo(context, id) {
          return axcessApi.getRequest('/document/' + id ).then(r => {
              if(r.data) context.commit('retrieveDocumentInfo', r.data)
              return r;
          })
      },

      addBox(context, boxList) {
        const id = state.document.layers[0] ? state.document.layers[0].id : null;
          return axcessApi.postRequest('/box', {
              document_id: state.docId,
              layer_id: id,
              boxList
          }).then(r=>{
              context.commit('addBox', r.data.data[0])
              return r;
          })
      },

      updateBox(context, boxList) {
          return axcessApi.patchRequest('/box', {
              document_id: state.docId,
              layer_id: state.document.layers[0].id,
              boxList
          } ).then(r=>{
              context.commit('updateBox', r.data.data[0])
              return r;
          })
      },

      deleteBox(context,id) {
          return axcessApi.deleteRequest('/box/' + id).then(r=>{
              context.commit('deleteBox', id)
              return r;
          })
      },

      async savePDF( context, id ){
        state.print = true;
          return axcessApi.postRequest('/merge', {docid:id}).then(r=>{
              if(r.data == 'success'){
                  state.calert = true;
                  setTimeout(function(){
                      state.calert = false;
                  }, 4000);
                  state.print = false;
                  return r;
              }else{
                  state.cerror = true;
                  setTimeout(function(){
                      state.cerror = false;
                  }, 4000);
                  state.print = false;
              }
          })
      },
      async addDocuments(context, formData) {
        state.loading = true;
        try {
            return axcessApi.postRequest('/document', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }}).then(r=>{
                context.commit('addDocuments', r.data)
                state.loading = false;
                return r;
            })
        } catch (error) {
          context.commit('setError', error.response.data)
          state.loading = false;
        }
        state.loading = false;
      },

      clearError(context) {
        context.commit('clearError')
      },

      updateDocument(context, document) {
          return axcessApi.patchRequest('document/' + document.id, document ).then(r=>{
              context.commit('updateDocument', r.data)
              return r;
          })
      },
  
      deleteDocument(context, id) {
          return axcessApi.deleteRequest('document/' + id).then((r)=>{
              context.commit('deleteDocument', r.data)
              return r;
          })
      },

      addMarkupDocument(context, formData) {
          return axcessApi.postRequest('/markup', formData).then((r)=>{
              state.alert = true;
              setTimeout(function(){
                  state.alert = false;
              }, 4000);
              return r;
          })
      },

      addHighlightDocument(context, formData) {
          return axcessApi.postRequest('/markup', formData).then(r=>r)
      },

      setBoxColor({commit}, recStyle) {
        var color = "#23707696";
        var border = "";
        switch (recStyle) {
          case "black":
            color = "#000";
            break;
          case "white":
            color = "#ffffff";
            border = "2px solid black";
            break;
          case "whiteNoBorder":
            color = "#ffffff";
            border = "none";
            break;
          case "transparent":
            color = "#23707696";
            border = "none";
            break;
          case "original":
            color = "rgba(255, 255, 255, 0)";
            border = "none";
            break;
          default:
            color = "#23707696";
            border = "none";
            break;
        }
        commit('setBoxColor', color);
        commit('setBoxBorder', border);
      },
  
      setPreview({commit}, preview) {
        commit('setPreview', preview);
      },


      async setPDFPage({commit}, pagePdf) { 
        commit('setPDFPage', null);
        const promises = range(1, pagePdf.numPages+1).
        map( number => pagePdf.getPage(number));
        await Promise.all(promises).then( pages => commit('setPDFPage', [...pages]) );
      },
    async generateConsultDoc(context, consultPerson ){
        let docArr = [];
        let loadingTask;
        await consultPerson.forEach( a => {

            let reqCode = this.state.request.requestItem.reqCode;
            let docTimestamp = reqCode + "-Consultation-"+moment().unix()+".pdf";
            let totalPage = a.children.reduce( (sum,el )=> el.children.length + sum , 0);
            console.log("T page : " + totalPage);
            let cpc = 0;
            let counter = 1;

            //Initialize jsPDF
            let jspdf = new jsPDF({  orientation: 'P',  format: 'letter',  unit: 'pt'  });
            let response = new Promise( resolve => {
                a.children.forEach( async (b) => {
                    const preview = `${axios.defaults.baseURL}/document/file/${b.id}`;
                    if( docArr.filter(c=>c.id === b.id ).length ){
                        loadingTask = docArr.filter(c=>c.id === b.id )[0];
                    }else{
                        loadingTask = pdfjs.getDocument(preview);
                        docArr.push({id:b.id,pdf:loadingTask});
                    }
                    if( cpc === a.children.length ){
                        resolve;
                    }else{
                        cpc++;
                    }
                });
            })
            if(response){
                a.children.forEach( c => {
                   let doc = docArr.filter( i => i.id === c.id ).map( j=> j.pdf );
                   // Setup Boxes on doc
                   let book = state.documents.filter( item => item.id === c.id );
                    let boxes = [];
                    if( book[0] && book[0].layers) {
                        book[0].layers.forEach(item => {
                            item.boxes.forEach(box => {
                                boxes.push(box)
                            })
                        });
                    }

                    doc[0].promise.then( async function(pdf) {
                        for(let i=1; i <= pdf.numPages; i++){
                            new Promise(proResolve => {
                                if( c.children.filter( k => k.id === i ).length )
                                pdf.getPage(i).then(function(page) {
                                    let viewport = page.getViewport({ scale: (800/page.view[2]) });
                                    let canvas = document.createElement('canvas');
                                    let ccontext = canvas.getContext('2d');
                                    canvas.height = viewport.height;
                                    canvas.width = viewport.width;

                                    let renderContext = {
                                        canvasContext: ccontext,
                                        viewport: viewport
                                    };
                                    let renderTask = page.render(renderContext);
                                    renderTask.promise.then(async function () {
                                        jspdf.setPage(counter)
                                        //Setup Main Image
                                        jspdf.addPage(612, 791);
                                        let dataUrl = canvas.toDataURL();
                                        jspdf.addImage(dataUrl, "JPEG",0,0,612, 791,undefined,"FAST");

                                        //Setup Page numbers
                                        jspdf.setFontSize(11)
                                        jspdf.setTextColor(0,0,0);
                                        jspdf.setFont('Helvetica');

                                        //Page Counter
                                        let s = String(counter);
                                        while (s.length <  3) {s = "0" + s;}

                                        jspdf.text(402, (24), reqCode +' PAGE No: '+ s);
                                        boxes.forEach(box => {
                                            if(state.rectangleStyle && (state.rectangleStyle == 'whiteNoBorder')) {
                                                jspdf.setDrawColor(255, 255, 255);
                                                jspdf.setFillColor(255,255,255);
                                                jspdf.setTextColor(0,0,0);
                                            }else if(state.rectangleStyle && (state.rectangleStyle == 'white')){
                                                jspdf.setDrawColor(0, 0, 0);
                                                jspdf.setFillColor(255,255,255);
                                                jspdf.setTextColor(0,0,0);
                                            }else{
                                                //Setup Reduction
                                                jspdf.setDrawColor(0, 0, 0);
                                                jspdf.setFillColor(0,0,0);
                                                jspdf.setTextColor(255,255,255);
                                            }
                                            if(state.rectangleStyle && (state.rectangleStyle == 'transparent')){
                                                var c = document.createElement("canvas");
                                                var ctx = c.getContext("2d");
                                                ctx.fillStyle = "#23707696";
                                                ctx.fillRect(0, 0, (box.width*0.765),((791/viewport.height)*box.height));
                                                var dataUrl = c.toDataURL();
                                            }

                                            //jspdf.addImage(dataUrl, "JPEG",0,0,612, 791,undefined,"FAST");
                                            if(state.rectangleStyle && (state.rectangleStyle != 'original')){
                                                if(box.pageNum == page.pageNumber){
                                                    if(state.rectangleStyle && (state.rectangleStyle == 'transparent'))
                                                        jspdf.addImage(dataUrl, "PNG",box.left*0.765,((791/viewport.height)*box.top),(box.width*0.765), ((791/viewport.height)*box.height+60),undefined,"FAST");
                                                    else
                                                        jspdf.rect(box.left*0.765, ((791/viewport.height)*box.top), (box.width*0.765), ((791/viewport.height)*box.height), 'FD'); // filled square
                                                }
                                            }

                                        });

                                        let linespace = 0;
                                        //Setup Clause
                                        boxes.forEach(box => {
                                            if(box.pageNum == page.pageNumber){
                                                jspdf.text((box.left*0.765), (((791/viewport.height)*box.top)+10), box.clausesUsed);
                                                jspdf.text(0, linespace+12, box.clausesUsed);
                                            }
                                        });
                                        //setTimeout(resolve, Math.random() * 1000);
                                        state.processState = Math.floor(( 100 * parseInt(counter))/parseInt(totalPage));

                                        //if(i==pdf.numPages)setTimeout(newresolve, Math.random() * 2000);
                                        console.log('Total Page: ', totalPage, 'Counter: ', counter );
                                        if( totalPage === counter ){
                                            jspdf.deletePage(1);
                                            var data = new Blob([jspdf.output("blob")], {
                                                type: "application/pdf"
                                            });

                                            var formData = new FormData();
                                            state.documents.filter(item=>item.id === c.id);
                                            formData.append("document_id", c.id);
                                            formData.append("docType", "highlight");
                                            formData.append("data", data, docTimestamp);
                                            console.log('save');
                                            setTimeout(
                                                proResolve,2000)
                                            jspdf.save(docTimestamp);

                                            state.processState = 0;
                                            state.conLoading = false;
                                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
                                            new Promise((resolve, reject) => {
                                              axcessApi.postRequest('/markup', formData)
                                                    .then(response => {
                                                        state.alert = true;
                                                        setTimeout(function(){
                                                            state.alert = false;
                                                        }, 4000);
                                                        resolve(response);
                                                    })
                                                    .catch(error => {
                                                        reject(error)
                                                    })
                                            })
                                        }counter++;
                                    });


                                });
                            })
                        }
                    });
                });

            }
        });



    },
      async setupConMarkup(context){
          console.log('here');
        state.conLoading = true;
        var documents = [];
        state.selectedDocs.forEach(doc=> {
            let docObj = state.documents.filter(item=>item.name === doc);
            documents.push( docObj[0] );
        });
        var jspdf = new jsPDF({  orientation: 'P',  format: 'letter',  unit: 'pt'  });
        const pagesAll = documents.reduce((t,{pageCount})=>t+pageCount,0);
        state.markedPages = pagesAll;
        var totalpage = 1;
        for(let k=0; k< documents.length; k++ ){
          await new Promise(newresolve => {
              var doc = documents[k];
              var boxes = [];
              if( doc && doc.layers) {
                doc.layers.forEach(item => {
                  item.boxes.forEach(box => {
                    boxes.push(box)
                  })
                });
              }
              
              var pageCount = 1,
              flag=1;
              state.documents.filter(d => d.status == "Registered").forEach(item=>{
                if( item.id == doc.id )
                  flag=0;
                if( flag == 1 )
                  pageCount += item.pageCount;
              });

              var reqCode = this.state.request.requestItem.reqCode;
              console.log(reqCode,boxes);
              var docId = doc.id;
              var docname = state.conDocname;
              const preview = `${axios.defaults.baseURL}/document/file/${docId}`;
              var loadingTask = pdfjs.getDocument(preview);
              
              loadingTask.promise.then( async function(pdf) { 
                for( let i=1; i<=pdf.numPages; i++ ){
                  await new Promise(resolve => {
                    pdf.getPage(i).then(function(page) { 
                        var viewport = page.getViewport({ scale: (800/page.view[2]) });
                        var canvas = document.createElement('canvas');
                        var ccontext = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                      
                        var renderContext = {
                          canvasContext: ccontext,
                          viewport: viewport
                        };
                        var renderTask = page.render(renderContext);
                        renderTask.promise.then(async function () {
                              jspdf.setPage(totalpage)
                              //Setup Main Image
                              jspdf.addPage(612, 791);
                              var dataUrl = canvas.toDataURL();
                              jspdf.addImage(dataUrl, "JPEG",0,0,612, 791,undefined,"FAST");

                              //Setup Page numbers
                              var s = String(pageCount++);
                              while (s.length <  3) {s = "0" + s;}
                                if( ! docname.includes( '-Release-') ){
                                    jspdf.setFontSize(9);
                                    jspdf.setFont('Helvetica');
                                    jspdf.setTextColor(0,0,0);
                                    jspdf.text(402, (24), reqCode +' PAGE Nso: '+ s);
                                }

                              
                              boxes.forEach(box => {
                                if(state.rectangleStyle && (state.rectangleStyle == 'whiteNoBorder')) {
                                  jspdf.setDrawColor(255, 255, 255);
                                  jspdf.setFillColor(255,255,255);
                                  jspdf.setTextColor(0,0,0);
                                }else if(state.rectangleStyle && (state.rectangleStyle == 'white')){
                                  jspdf.setDrawColor(0, 0, 0);
                                  jspdf.setFillColor(255,255,255);
                                  jspdf.setTextColor(0,0,0);
                                }else{
                                  //Setup Reduction
                                  jspdf.setDrawColor(0, 0, 0);
                                  jspdf.setFillColor(0,0,0);
                                  jspdf.setTextColor(255,255,255);
                                }
                                if(state.rectangleStyle && (state.rectangleStyle == 'transparent')){
                                  var c = document.createElement("canvas");
                                  var ctx = c.getContext("2d");
                                  ctx.fillStyle = "#23707696";
                                  ctx.fillRect(0, 0, (box.width*0.765),((791/viewport.height)*box.height));
                                  var dataUrl = c.toDataURL();
                                }
                              
                              //jspdf.addImage(dataUrl, "JPEG",0,0,612, 791,undefined,"FAST");
                              if(state.rectangleStyle && (state.rectangleStyle != 'original')){
                                if(box.pageNum == page.pageNumber){
                                  if(state.rectangleStyle && (state.rectangleStyle == 'transparent'))
                                  jspdf.addImage(dataUrl, "PNG",box.left*0.765,((791/viewport.height)*box.top),(box.width*0.765), ((791/viewport.height)*box.height+60),undefined,"FAST");
                                  else
                                  jspdf.rect(box.left*0.765, ((791/viewport.height)*box.top), (box.width*0.765), ((791/viewport.height)*box.height), 'FD'); // filled square  
                                }
                              }
                              
                            });

                              //Setup Clause
                                let linespace = 0
                              boxes.forEach(box => {
                                if(box.pageNum == page.pageNumber)
                                jspdf.text((box.left*0.765), (((791/viewport.height)*box.top)+10), box.clausesUsed);
                                jspdf.text(0, linespace+12, box.clausesUsed);
                              });
                              setTimeout(resolve, Math.random() * 1000);
                              state.processState = Math.floor(( 100 * parseInt(totalpage))/parseInt(pagesAll));
                              if(i==pdf.numPages)setTimeout(newresolve, Math.random() * 1000);
                              if( totalpage == pagesAll ){
                                jspdf.deletePage(1);
                                var data = new Blob([jspdf.output("blob")], {
                                  type: "application/pdf"
                                });
                                
                                var formData = new FormData();
                                formData.append("document_id", docId);
                                formData.append("docType", "release");
                                formData.append("total_pages", totalpage);
                                formData.append("data", data, docname);
                                //jspdf.save(docname);

                                state.processState = 0;
                                state.conLoading = false;
                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
                                new Promise((resolve, reject) => {
                                  axcessApi.postRequest('/markup', formData)
                                  .then(response => {
                                    state.alert = true;
                                    setTimeout(function(){
                                      state.alert = false;
                                  }, 4000);
                                    resolve(response);
                                    return true;
                                  })
                                  .catch(error => {
                                    reject(error)
                                  })
                                })
                              }totalpage++;
                        });
                    });
                  });
                }
              });
            });
        }
        return true;
      },

      setNumPages({commit}, numPages) {
        commit('setNumPages', numPages);
      },

      setMode({commit}, mode) {
        commit('setMode', mode);
      },

      setDocumentId({commit}, id) {
        commit('setDocumentId', id);
      },

      setCurrentPage({commit}, pageNum) {
        commit('setCurrentPage', pageNum);
      },

      setIsDrawing({commit}, value) {
        commit('setIsDrawing', value)
      },
      setIsNote({commit}, value) {
        commit('setIsNote', value)
      },

      toggleDrawer({commit}) {
        commit('toggleDrawer')
      },

      setMarkupType({commit}, value) {
        commit('setMarkupType', value)
      },

      setShowClause({commit}, value) {
        commit('setShowClause', value)
      },

      setRectangleStyle({commit}, value) {
        commit('setRectangleStyle', value)
      },

      setExtension({commit}, value) {
        commit('setExtension', value)
      },

      setSelectedDocs({commit}, value) {
        commit('setSelectedDocs', value)
      },
      updateCondocname({commit}, value){
        commit('updateCondocname',value);
      }

}

const mutations = {
    setEnhancement(state, content){
      Object.assign(state.enhancement, content);
    },
    setWatermarkText(state,content){
      state.watermarkText = content;
    },
    retrieveDocuments(state, documents) {
      state.documents = documents.data;
      state.loading = false;
    },
    retrieveLayerBoxes(state, layerboxes) {
      state.layerboxes = layerboxes.data;
      state.loading = false;
    },
    retrieveLayers(state, layers) {
      state.layers = layers.data;
      state.loading = false;
    },
    getScale(state, value){
      state.scale = value;
    },
    setNotesByPage(state, value){
        state.notes = value;
    },
    updateNotesByPage(state,value){
        state.notes = state.notes.filter(i=>i.id !== value.id );
        state.notes.push(value);
    },
    deleteNote(state,value){
      state.notes = state.notes.filter( i => i.id !== value)
    },
    setPageStatus( state, value ){
        state.pageStatus = value;
    },
    updateCondocname(state, value){
      state.conDocname = value
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    retrieveDocumentInfo(state, document) {
      state.document = document
    },

    retrieveFile(state, file) {
      state.file = file;
    },

    printdoc( state, file){
      state.printdoc = file;
    },

    setPreview(state, preview) {
      state.preview = preview;
    },

    setPDFPage(state, pdf) {
      state.pagePdf = pdf;
    },

    setNumPages(state, numPages) {
      state.numPages = numPages;
    },

    setMode(state, mode) {
      state.mode = mode;
    },

    setDocumentId(state, id) {
      state.docId = id;
    },

    setCurrentPage(state, pageNum) {
      state.currentPage = pageNum;
    },

    setIsDrawing(state, value) {
      state.isDrawing = value;
    },
    setIsNote(state, value) {
      state.isNote = value;
    },

    toggleDrawer(state) {
      state.isDrawing = !state.isDrawing;
    },

    updateBox(state, box) {
        state.document.layers[0].boxes.filter(i=>i.id !== parseInt(box.id) )
        state.document.layers[0].boxes.push(box);
    },

    addBox(state, box) {
      if(state.document.layers[0]) {
        state.document.layers[0].boxes.push(box)
      }
    },

    deleteBox(state, id) {
        state.document.layers[0].boxes = state.document.layers[0].boxes.filter(item => item.id !== parseInt(id))
    },

    setMarkupType(state, value) {
      state.markupType = value
    },

    addDocuments(state, docs) {
      docs.forEach(file => {
        state.documents.push(file)
      })
    },

    updateDocument(state, document) {
        state.documents = state.documents.filter(i=>i.id !== document.id)
        state.documents.push(document);
    },

    deleteDocument(state, id) {
        state.documents = state.documents.filter(i=>i.id !== id);
    },

    setError(state, error) {
      state.error = error;
      state.errored = true;
    },

    clearError(state) {
      state.error = '';
      state.errored = false;
    },

    setShowClause(state, value) {
      state.showClauses = value;
    },

    setRectangleStyle(state, value) {
      state.rectangleStyle = value;
    },

    setBoxColor(state, color) {
      state.boxColor = color;
    },

    setBoxBorder(state, border) {
      state.boxBorder = border;
    },

    setExtension(state, value) {
      state.extension = value;
    },
    setSelectedDocs(state, value = []){
      state.selectedDocs = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
"use strict";

import Vue from 'vue';
import axios from "axios";

// var AUTH_TOKEN = localStorage.getItem('access_token') || null;
// Full config:  https://github.com/axios/axios#request-config

let tenant = window.location.host.split('.')[0];
//axios.defaults.baseURL = process.env.VUE_APP_APIURL;
let mode = process.env.VUE_APP_MODE;
axios.defaults.baseURL = '';
if ( mode === "dev") { 
      if( window.location.pathname.includes('/admin') ){
        axios.defaults.baseURL = process.env.VUE_APP_ADMIN_URL;
      }else{
          axios.defaults.baseURL = location.protocol+"//"+window.location.host.replace(tenant, tenant + '.api')+"/api";
          axios.defaults.baseURL = axios.defaults.baseURL.replace( 8080, 8000 );    
          
          //axios.defaults.baseURL = process.env.VUE_APP_APIURL;
      }

      
  } else {
      axios.defaults.baseURL = location.protocol+"//"+window.location.host.replace(tenant, tenant + '.api')+"/api";    
  }
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//console.log(axios.defaults.baseURL,window.location.pathname);
let config = {
  baseURL: axios.defaults.baseURL,
  timeout: 60 * 10000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
    config.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;

import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store';
import axios from 'axios';
import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
//import Echo from 'laravel-echo';
// import ElementUI's styles
import 'element-ui/lib/theme-chalk/index.css';
// import this package's styles
import 'element-tiptap/lib/index.css';
import { i18n } from "@/plugins/i18n";
import PdfViewer from 'vue-pdf-search-keyword/packages/index'
import roleManager from "@/plugins/roleManager";
import moment from "moment/min/moment-with-locales";
import html2canvas from "html2canvas";

Vue.config.productionTip = false
console.clear();
console.log("%cStop!", "color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;");
console.log("%cThis window is only meant for project developers.", "color: gray; font-family: sans-serif; font-size: 1.5em; font-weight: bolder; text-shadow: #000 1px 1px;");
console.log("%cA%cXCESS%c1","color:#0e3b40;font-size:100px;","color:#016b89;font-size:100px;","color:#0e3b40;font-size:100px");
/* 
window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
  wsPort: 6001,
  forceTLS: false,
  disableStats: true
}); */
window.html2canvas = html2canvas;
//filter by permission
router.beforeEach(async (to, from, next) => {
  let base = window.location.hostname;
  let domains =[
    '127.0.0.1',
    'localhost',
    'api.localhost',
    'demo.api.axcess1.ca',
    'axcess1.com',
    'axcess1.test'
  ];
  if(domains.includes(base) && to.name != 'adminlogin' && to.name != 'admin'){
    if(localStorage.getItem("access_token"))
    return window.location.href='/admin'
    else
    return window.location.href='/admin/login'
  }
  //A Logged-in user can't go to login page again

  if ((to.name === 'login' || to.name === 'adminlogin') && localStorage.getItem("access_token")) {
    if (to.name === 'login'){
      router.push({
        name: 'dashboard'
      }).catch(() => {}); 
    }
    if (to.name === 'adminlogin'){
      router.push({
        name: 'admin'
      }).catch(() => {}); 
    }

    //the route requires authentication
  } else if(to.meta.requiresAuth) {

    if (!localStorage.getItem("access_token")) {

      //user not logged in, send them to login page 
      router.push({
        name: 'login',
        query: {
          to: to.name
        }
      }).catch(() => {}); 

    } else {
      if (!roleManager.hasAccess(to.name)) {
        router.push({name: 'error'}).catch(() => {}); 

      }
    }
  }

  return next()
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  const{
    response: {status}
  } = error;
  if(status === 401) {
    store.dispatch('destroyToken');
    if( window.location.pathname.includes('/admin') ){
      router.push({ name: "adminlogin" }).catch(() => {}); 
    }
    else{
      router.push({ name: "login" }).catch(() => {}); 
    }
  }
})

Vue.filter('leadingnum', function (text, size = 4) {
  let s = String(text);
  while (s.length < (size || 3)) {s = "0" + s;}
  return s;
})
Vue.filter('fromNow', function (date) {
  moment.locale(localStorage.getItem("language"))
  return date ? moment(date).fromNow() : ''
})
Vue.filter('formatDate', function (date) {
  return date ? moment(date).format("YYYY-MM-DD") : ''
})
Vue.filter( 'currentDate',function (date){
  moment.locale(localStorage.getItem("language"))
  return date ? moment(new Date(date)).format("MMMM DD, YYYY"): ''
});

Vue.prototype.$checkPermission=(name)=> {

  
  return  JSON.parse(localStorage.getItem("user")).permission.map(pr => pr.name).includes(name);


}

Vue.prototype.addHolidays = (startDate,DueDate,holidayList) => {
  let newDueDate=DueDate;
  holidayList.forEach(date => {
     if(moment(date.value).isBetween(moment(startDate), moment(DueDate))){
     newDueDate = moment(new Date(DueDate)).add(1, 'day');
     date.value="";
     }
     
  })

   if(DueDate!=newDueDate)
    return Vue.prototype.addHolidays(startDate,newDueDate,holidayList)

  return moment(newDueDate).format('YYYY-MM-DD');
  }

Vue.prototype.addBusinessDays = (date, days) => {
  var d = moment(new Date(date)).add(Math.floor(days / 5) * 7, 'd');
  var remaining = days % 5;
  while (remaining) {
    d.add(1, 'd');
    if (d.day() !== 0 && d.day() !== 6)
      remaining--;
  }
  return moment(d).format('YYYY-MM-DD');
};

// use ElementUI's plugin
Vue.use(ElementUI);
// use this package's plugin
Vue.use(ElementTiptapPlugin, { /* plugin options */ });

Vue.config.devtools = false;
// Now you register `'el-tiptap'` component globally.
Vue.prototype.$http = axios
new Vue({
  router,
  i18n,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.use(PdfViewer);
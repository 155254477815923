import axcessApi from "@/plugins/axcessApi";
import LogsHistory from "@/plugins/logs_history";



const state = {
    token: localStorage.getItem('access_token'),
    templates:[],
    language:""


}



const getters = {
  
    getTemplatebyLanguage(state) {
        var result=state.templates;
        if(state.language!="" &&state.language!=undefined){
        result= state.templates.filter(item => 
            item.language == state.language)
        }
        return result;
    }
    
}



const actions = {

    setLanguage(context,selected){
        context.commit('setLanguage', selected)
    },

    retriveTemplate(context){
      
        return axcessApi.getRequest("/template" ).then(r => {
            if(r.data)
            context.commit('setTemplate', r.data)
            return r;
          
        })
    },


    getTemplate(context,id){
        return axcessApi.getRequest("/template/"+ id ).then(r => {
            if(r.data) context.commit('singleTemplate', r.data)
            return r;
        })
    },
    
    setTemplate(context,template){
        if( ! template.id ){
          return axcessApi.postRequest("/template", template).then(r=>{
              context.commit('addTemplate', r.data)
              return r;
          })
        }else{
            return axcessApi.patchRequest("/template/"+template.id, template).then(r=>{
                context.commit('updateTemplate', r.data)
                return r;
            })
        }
    },

}

const mutations = {

    setLanguage(state, selected) {
        state.language = selected;
    },

    setTemplate(state, template) {
        state.templates = template;
    },

    singleTemplate(state, template) {
        state.currentTemp = template;
    },

    addTemplate(state, template) {
        let $logs_history= new LogsHistory(state.templates,template.id,template,'template');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        state.templates.push( template );
    },
    updateTemplate(state, template) {
        let $logs_history= new LogsHistory(state.templates,template.id,template,'template');
        $logs_history.createLog($logs_history.deepDiffMapper($logs_history.getOG(),$logs_history.getNew()));
        let newTemplate = state.templates.filter(item=> item.id !== template.id );
        newTemplate.push( template );
        state.templates = newTemplate;
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

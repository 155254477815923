import axcessApi from "@/plugins/axcessApi";
const state = {
    token: localStorage.getItem('admin_access_token') || null,
    loading: true,
    tenants:[]
}
const getters = {
    getSingleEventAction : (state) => () => {
        return state.tenants
    }
}
const actions = {
    getTenants(context){
        return axcessApi.getRequest(`/tenants`).then(r=>{
            context.commit('getTenants', r.data.data );
            return r;
        })
    },
    addTenant(context,payload){
        state.loading = true;
        return axcessApi.postRequest('/tenant', payload ).then(r=>{
            context.commit( 'addTenant', r.data );
        })
    },
    updateTenant(context,payload){
        return axcessApi.patchRequest('/tenant/' + payload.id , payload.data ).then(r=>{
            context.commit( 'updateTenant', r.data );
        })
    },
    deleteTenant(context,payload){
        return axcessApi.deleteRequest('/tenant/' + payload ).then(()=>{
            context.commit( 'deleteTenant', payload );
        })
    }
}

const mutations = {
    getTenants(state, payload){
        state.tenants = payload;
        state.loading = false;
    },
    addTenant(state,payload){
        state.tenants.push(payload);
        state.loading = false;
    },
    updateTenant(state,payload){
        state.tenants = state.tenants.filter(i=> i.id !== payload.id );
        state.tenants.push(payload);
    },
    deleteTenant(state,payload){
        state.tenants = state.tenants.filter(i=> i.id !== payload );
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
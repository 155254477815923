import axcessApi from "@/plugins/axcessApi";
export default class logs_history{
      VALUE_CREATED= 'created';
      VALUE_UPDATED= 'updated';
      VALUE_DELETED= 'deleted';
      VALUE_UNCHANGED= 'unchanged';
      logs= [];
      og_item= {};
      new_item= {};
      module= '';
      constructor(arr,id,new_item,module){
        this.setOG(arr,id);
        this.setNew(new_item);
        this.setModule(module);
      }
      retreiveLogs(){
        return axcessApi.getRequest('/logshistory' ).then(r => {
          this.setLogs(r.data);
        })
      }
      setLogs(logs) {
        this.logs=logs;
      }
      setModule(module) {
        this.module=module;
      }
      setNew(new_item) {
        this.new_item=new_item;
      }
      setOG(arr, id) {
        this.og_item = arr.filter(item=>item.id==id)[0];
      }
      getNew() {
        return this.new_item;
      }
      getOG() {
        return this.og_item;
      }
      getModule() {
        return this.module;
      }
      getLogs() {
        return this.logs;
      }
      createLog(result) {
        let res=[];
        if(this.isObject(result))
        res.push(result)
        if(this.isArray(result))
        res=result;
        
        if(res.length>0)
            return axcessApi.postRequest('/logshistory', res ).then(r=>{
              this.retreiveLogs().then(()=>{
                this.logs.push(r.data);
              });
            })
      }
      formatResult(result) {
        return Object.entries(result).map( j=>{
          return {field:j[0],data:j[1]}
        }).filter(item=>item.data.type!='unchanged' && item.data.type && item.field!='updated_at' && item.field!='updated_by' && item.field!='created_at').map(k=>{return {module:this.module,field:k.field,old_data:this.og_item[k.field],new_data:k.data.new_data,type:k.data.type,item_id:k.data.item_id}});
      }
      deepDiffMapper(obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            module:this.module,
            field:null,
            old_data:obj1?obj1:null,
            new_data: obj1?obj2:null,
            type: this.compareValues(obj1, obj2),
            item_id: this.new_item.id,
          };
        }
  
        let diff = {};
        for (let key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }
  
          let value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }
  
          diff[key] = this.deepDiffMapper(obj1[key], value2);
        }
        for (let key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }
  
          diff[key] = this.deepDiffMapper(undefined, obj2[key]);
        }
        
        return this.formatResult(diff);
  
      }
      compareValues(value1, value2) {
        if (value1 == value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() == value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 == undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 == undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      }
      isFunction(x) {
        return Object.prototype.toString.call(x) == '[object Function]';
      }
      isArray(x) {
        return Object.prototype.toString.call(x) == '[object Array]';
      }
      isDate(x) {
        return Object.prototype.toString.call(x) == '[object Date]';
      }
      isObject(x) {
        return Object.prototype.toString.call(x) == '[object Object]';
      }
      isValue(x) {
        return !this.isObject(x) && !this.isArray(x);
      }
}